import React, { useContext, useEffect, useState } from 'react';
import ReactDOM                                   from 'react-dom';

import { EApiContext }                            from '../../context/EApi';
import CartIcon                                   from './CartIcon';

const CartPortal = () => {
  const [renderCart, setRenderCart] = useState(false);
  const { settings } = useContext(EApiContext);
  let element;

  if (typeof window !== 'undefined') {
    element = document.getElementById('int-cart');
  }

  useEffect(() => {
    if (settings.data) {
      if (settings.data.enable_cart && !settings.data.maintenance_mode) {
        setRenderCart(true);
      }
    }
  }, [settings, element])

  if (element && renderCart) {
    return ReactDOM.createPortal(<CartIcon/>, element)
  } else {
    return null;
  }
}

export default CartPortal;