import styled from '@emotion/styled';

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  max-height: 160px;
  max-width: ${props => props.width ? props.width : props.theme.config.components.wrapper.width}px;
  margin: auto;
  z-index: 100;
  //position: sticky;
  top: 0;
  //> * {
  //  width: 20%;
  //}
`;

export const Logo = styled.div`
  width: 75%;
  max-width: 215px;
  padding: 20px 0;
  margin: auto;
  svg {
    width: 100%;
    max-width: 250px;
    max-height: 90px;
    height: auto;
  }
`;

export const Nav = styled.div`
  ul {
    display: flex;
    justify-content: ${props => props.second && 'flex-end'};
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      padding: 0 20px;
      margin: 0;
      position: relative;
      &:hover {
        .subnav {
          display: flex;
        }
      }
      ul.subnav {
        display: none;
        position: absolute;
        z-index: 100;
        flex-direction: column;
        margin: 0;
        background-color: #fff;
        li {
          padding: 10px;
          margin: 0 0 0 10px;
        }
      }
      a {
        color: unset;
        text-decoration: none !important;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 0.9rem;
      }
      & .current {
        //color: ${props => props.theme.brand_color};
        &::after {
          content: '';
          display: block;
          width: 70%;
          height: 3px;
          background-color: ${props => props.theme.brand_color};
        }
      }
      &:hover {
      > a {
        color: ${props => props.theme.brand_color};
        }
      }
    }
  }
`;

export const CartSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 15px;
  a {
    color: unset;
    display: block;
    height: 32px;
    svg {
      width: 32px;
      height: 32px;
    }
  }
`;