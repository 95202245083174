import React                    from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { FormattedMessage }     from 'react-intl';
import { find }                 from 'lodash';


import { findT }   from '../../_helpers';
import PhoneNumber from '../../text/PhoneNumber/PhoneNumber';
import Link        from '../../elements/Link/Link';

const ContactWidget = props => {
  const translations = findT(props.widget.translations, props.lang);
  return <StaticQuery query={query} render={data => {
    const { emails, numbers } = data.directusCompany;
    const email = find(emails, email => email.primary).email;
    const phoneNumber = find(numbers, number => number.type === 'phone' && number.primary);
    const mobileNumber = find(numbers, number => number.type === 'mobile' && number.primary);
    return (
      <>
        <h4>{translations.header
          ? translations.header
          : <FormattedMessage id="components.widgets.contactWidget.defaultHeader" tagName="span"/>}
        </h4>
        <ul>
          {phoneNumber &&
            <li><FormattedMessage id="components.widgets.addressWidget.tel" tagName="span"/> <PhoneNumber
              number={phoneNumber.number}/>
            </li>}
          {mobileNumber &&
            <li><FormattedMessage id="components.widgets.addressWidget.mobile" tagName="span"/> <PhoneNumber
              number={mobileNumber.number}/>
            </li>}
          {email &&
            <li><FormattedMessage id="components.widgets.addressWidget.email" tagName="span"/> <Link
              external
              to={`mailto:${email}`}
              title="Email">{email}</Link>
            </li>}
        </ul>
      </>
    )
  }}/>
};

const query = graphql`
    query {
        directusCompany {
            emails {
                email
                primary
            }
            numbers {
                number
                primary
                type
            }
        }
    }
`;

export default ContactWidget;
