import React, { useContext }    from 'react';
import { Helmet }               from 'react-helmet';
import PropTypes                from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import { LanguageContext } from '../../../providers';
import { findT }           from '../../_helpers';

function SEO({ description, meta, image: metaImage, title, keywords }) {
  const { language } = useContext(LanguageContext);
  return (
    <StaticQuery
      query={graphql`
       {
          directusProject {
            translations {
                language
                seo_title
                seo_description
                seo_keywords
            }
          }
          directusCompany {
            company_name
          }
        }`}
      render={data => {
        const seo = findT(data.directusProject.translations, language);
        const contact = data.directusCompany.company_name;
        const metaDescription = description || seo.seo_description;
        const seoKeywords = keywords.length === 0 ? seo.seo_keywords : keywords;
        const seoTitle = title || seo.seo_title;
        // TODO Image
        const image = metaImage && metaImage.src ? `${data.site.siteMetadata.siteUrl}${metaImage.src}` : null;
        return (
          <Helmet
            htmlAttributes={{
              lang: language,
            }}
            title={seoTitle}
            titleTemplate={seo.seo_title === seoTitle ? `${seo.seo_title}` : `%s | ${seo.seo_title}`}
            meta={
              [
                {
                  name: 'description',
                  content: metaDescription
                },
                {
                  property: 'og:title',
                  content: seoTitle
                },
                {
                  property: 'og:description',
                  content: metaDescription
                },
                {
                  name: 'twitter:creator',
                  content: contact.name
                },
                {
                  name: 'twitter:title',
                  content: seoTitle
                },
                {
                  name: 'twitter:description',
                  content: metaDescription
                }
              ]
                .concat(
                  seoKeywords.length > 0
                    ? {
                      name: `keywords`,
                      content: seoKeywords.join(`, `),
                    }
                    : []
                )
                .concat(
                  metaImage
                    ? [
                      {
                        property: 'og:image',
                        content: image
                      },
                      {
                        property: 'og:image:width',
                        content: metaImage.width
                      },
                      {
                        property: 'og:image:height',
                        content: metaImage.height
                      },
                      {
                        name: 'twitter:card',
                        content: 'summary_large_image'
                      }
                    ]
                    : [
                      {
                        name: 'twitter:card',
                        content: 'summary'
                      }
                    ]
                )
                .concat(meta)
            }
          />
        );
      }}
    />
  );
}

SEO.defaultProps = {
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  image: PropTypes.shape({
    src: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string
  }),
  meta: PropTypes.array,
  title: PropTypes.string,
};

export default SEO;