import React, { useEffect, useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import AnimateHeight from 'react-animate-height';

const Expander = ({ title, close, children }) => {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (close) {
      setHeight(0)
    }
  }, [close]);

  const toggle = () => {
    setHeight(height === 0 ? 'auto' : 0)
  };
  return (
    <>
      <button className='int-expander-toggler' onClick={toggle}>{title} {height !== 0 ? <FaChevronUp/> : <FaChevronDown/>}</button>
      <AnimateHeight duration={500} height={height}>
        {children}
      </AnimateHeight>
    </>
  )
};

export default Expander;