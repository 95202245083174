import styled            from '@emotion/styled';
import { readableColor } from 'polished';

export const Footer = styled.footer`
  width: 100%;
  background-color: var(--chakra-colors-base-200);
  color: ${props => readableColor(props.theme.colors.base['200'])} !important;;

  h4 {
    color: ${props => props.theme.brand_color} !important;
  }
`;

export const Inner = styled.div`
  padding-bottom: 50px;
  margin: auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 0.9rem;

  > div {
    margin: 0 20px;
    @media (max-width: 1260px) {
      width: 50%;
    }
    @media (max-width: 640px) {
      width: 100%;
    }

    h4 {
      margin-top: 30px;
    }

    address {
      font-style: normal;
      margin-bottom: 0;
    }

    a {
      color: unset;
    }
  }

  ul {
    margin: 0;

    li {
      font-weight: 300;
      margin: 0;
      list-style-type: none !important;
    }
  }
}
`;

export const Social = styled.div`
  nav {
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }

  ul {
    margin: 0;

    li {
      margin: 0;
      border: 0;

      a {
        svg {
          color: #fff !important;
          width: 40px;
          height: 40px;
        }
      }
    }
  }
`;