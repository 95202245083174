import React, { useContext }                from 'react';
import { Link as GatsbyLink }               from 'gatsby';
import { FaExternalLinkAlt, FaRegEnvelope } from 'react-icons/fa'
import { AiOutlinePhone }                   from 'react-icons/ai'

import { AnchorLink } from '@interness/gatsby-plugin-anchor-links';

import { GlobalContext, LanguageContext } from '../../../providers';
import { isLinkExternal }                 from '../../_helpers';
import routes                             from '../../../../config/routes';

// TODO refactor this mess with different components
export const Link = (props) => {
  let { children, to, activeClassName, external, partiallyActive, anchor, ...other } = props;
  const { href } = other;
  const { language } = useContext(LanguageContext);
  const { togglePrimaryMenu, onSidebarClose } = useContext(GlobalContext);

  const onClickHandler = () => {
    togglePrimaryMenu(false);
    onSidebarClose()
    if (other.onClick) {
      other.onClick()
    }
  };

  let route;
  if (to && to.charAt(0) === '/') {
    route = to.substr(1)
  }
  const isRoute = !!routes[route];

  // Anchor
  if (anchor) {
    return <AnchorLink to={`${to}#${anchor}`} {...other} onClick={() => onClickHandler()}>{children}</AnchorLink>
  }

  // Link from MDX
  if (href && !to && !isLinkExternal(href) && !href.startsWith('mailto:')) {
    to = href;
  }

  if (isLinkExternal(to) || external || !to) {
    if (to && (!to.startsWith('mailto:') && !to.startsWith('/static/') && !to.startsWith('tel:'))) {
      if (!isLinkExternal(to)) {
        // In case of 'external' prop
        to = `//${to}`;
      }
    }
    if (to) {
      return (
        <a href={to} {...other} target='_blank' rel='noopener noreferrer' onClick={onClickHandler}>
          {to.startsWith('mailto:')
            ? <FaRegEnvelope style={{ verticalAlign: 'middle', marginRight: '5px' }}/>
            : to.startsWith('tel:')
              ? <AiOutlinePhone style={{ verticalAlign: 'text-top', marginRight: '5px' }}/>
              : <FaExternalLinkAlt style={{ verticalAlign: 'text-top', marginRight: '5px' }}/>
          }
          {children}
        </a>
      )
    } else {
      return (
        //eslint-disable-next-line
        <a style={{ cursor: 'pointer' }} {...other} target='_blank' rel='noopener noreferrer' onClick={onClickHandler}>
          <FaExternalLinkAlt style={{ verticalAlign: 'middle', marginRight: '5px' }}/> {children}
        </a>
      )
    }
  } else {
    return (
      <GatsbyLink to={isRoute ? routes[route][language] : to} activeClassName={activeClassName}
                  partiallyActive={partiallyActive} {...other}
                  onClick={onClickHandler}>
        {children}
      </GatsbyLink>
    )
  }
};

export default Link;
