import styled from '@emotion/styled';

export const J = styled.div`
  text-align: justify;
  h3, h4 {
    font-weight: bold;
  }
`;

export const Aside = styled.aside`
  h3, h4 {
    font-weight: bold;
  }
  p {
    margin-bottom: 0;
  }
  section {
    margin-bottom: 30px;
  }
`;