import React                       from 'react';
import paletteGenerator            from '@bobthered/tailwindcss-palette-generator';
import { ThemeProvider }           from '@emotion/react';
import { useStaticQuery, graphql } from 'gatsby';

import chakraTheme from '../theme/chakra-theme';

import {
  extendTheme,
  ThemeProvider as ChakraThemeProvider,
  GlobalStyle,
  PortalManager,
} from '@chakra-ui/react';

import CSSReset from './CSSReset';
import { readableColor } from 'polished';


const Root = ({ children }) => {
  const { theme } = useStaticQuery(query);
  const brandColor = paletteGenerator(theme.brand_color);
  const baseColor = paletteGenerator(theme.base_color);

  const colorVal = readableColor(theme.base_color);
  const darkTheme = colorVal === '#fff';
  const colorMode = darkTheme ? 'dark' : 'light';

  const combinedTheme = extendTheme({
    initialColorMode: colorMode,
    useSystemColorMode: false,
    colors: {
      brand: { ...brandColor.primary },
      base: { ...baseColor.primary }
    },
    config: theme.config,
  }, chakraTheme);

  theme.colorMode = colorMode;

  return (
    <ChakraThemeProvider theme={combinedTheme}>
      <ThemeProvider theme={theme}>
        <CSSReset/>
        <GlobalStyle/>
        <PortalManager>{children}</PortalManager>
      </ThemeProvider>
    </ChakraThemeProvider>
  )
}

export default Root;

const query = graphql`
    query {
        theme {
            ...CoreTheme
            ...ThemeTheme
        }
    }
`;