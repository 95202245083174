import { useContext }              from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { LanguageContext } from '../providers';
import { findT }           from '../components/_helpers';

export const useHeaderData = () => {
  const { language } = useContext(LanguageContext);
  const { directusProject, directusMenus, directusMenusNew } = useStaticQuery(
    graphql`
        query {
            directusProject {
                ...ProjectLogoWithSvg
                translations {
                    seo_title
                    language
                }
                config {
                    brand_config {
                        rolex_clock
                        rolex_api_key
                    }
                }
            }
            directusMenus(position: {eq: "header"}) {
                position
                translations {
                    language
                    config {
                        displayName
                        path
                        anchor
                        type
                        groups {
                            displayName
                            sub {
                                displayName
                                path
                                anchor
                            }
                        }
                        sub {
                            displayName
                            path
                            anchor
                        }
                    }
                }
            }
            directusMenusNew(position: {eq: "primary"}) {
                language
                position
                config {
                    anchor
                    display_name
                    groups {
                        display_name
                        sub {
                            anchor
                            display_name
                            path
                        }
                    }
                    path
                    route
                    sub {
                        display_name
                        path
                        path_prefix
                    }
                }
            }
        }
    `
  );
  const translations = findT(directusProject.translations, language);
  const menu = directusMenus ? findT(directusMenus.translations, language).config : null;
  const menuNew = directusMenusNew ? directusMenusNew.config : null;
  return {
    language,
    logo: directusProject.logo,
    seo_title: translations.seo_title,
    config: directusProject.config[0],
    menu,
    menuNew
  }
};
