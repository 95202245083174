import React, { useState, useReducer, useEffect } from 'react';
import { graphql, useStaticQuery }                from 'gatsby';

import { cartReducer, getCartFromLocal }       from '../reducers/cartReducer';
import { productReducer, setProductStructure } from '../reducers/productReducer';

export const StoreContext = React.createContext({});

export const StoreProvider = ({ children }) => {

  const { sitePlugin } = useStaticQuery(query);
  const [settings, setSettings] = useState({ loading: true, data: null, error: null });
  const [paypal, setPaypal] = useState(null);
  const [storeRootPath, setStoreRootPath] = useState('/' + sitePlugin.pluginOptions.path);
  const [projectName, setProjectName] = useState(null);
  const [cart, cartDispatcher] = useReducer(cartReducer, {}, getCartFromLocal);
  const [categoryFilter, setCategoryFilter] = useState(null);
  const [manufacturersFilter, setManufacturersFilter] = useState([]);
  const [subcategoriesFilter, setSubcategoriesFilter] = useState([]);
  const [attributesFilter, setAttributesFilter] = useState({});
  const [tagFilter, setTagFilter] = useState('');
  const [sort, setSort] = useState([]);
  const [meta, setMeta] = useState(null);
  const [page, setPage] = useState(0);
  const [currentProduct, currentProductDispatcher] = useReducer(productReducer, {}, setProductStructure);

  useEffect(() => {
    setPage(0);
  }, [categoryFilter, manufacturersFilter, subcategoriesFilter])

  useEffect(() => {
    if (settings.data) {
      const pp = settings.data.payment_methods.filter(({ payment_id }) => payment_id.id.toString() === '4');
      if (pp.length === 1) {
        setPaypal(settings.data.paypal_client_id);
      }
    }
  }, [settings])

  return <StoreContext.Provider
    value={{
      settings, setSettings,
      paypal, setPaypal,
      storeRootPath, setStoreRootPath,
      projectName, setProjectName,
      categoryFilter, setCategoryFilter,
      manufacturersFilter, setManufacturersFilter,
      subcategoriesFilter, setSubcategoriesFilter,
      attributesFilter, setAttributesFilter,
      tagFilter, setTagFilter,
      sort, setSort,
      cart, cartDispatcher,
      meta, setMeta,
      page, setPage,
      currentProduct, currentProductDispatcher,
    }}>{children}</StoreContext.Provider>
};

const query = graphql`
    query {
        sitePlugin(name: {eq: "@interness/ecommerce-addon"}) {
            version
            name
            pluginOptions {
                display_name
                path
            }
        }
    }
`;