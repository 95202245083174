export const endpoints = {
  api: process.env.GATSBY_API_ENDPOINT,
  project: 'announcements',
  orderApi: process.env.GATSBY_ORDER_API_ENDPOINT,
  thumbnails: `${process.env.GATSBY_API_ENDPOINT}/announcements/assets`
}

export const defaultQueryConfig = {
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
}

export const settingsFields = [
  'attribute_filters.*',
  'call_to_action_text',
  'global_footer',
  'category_filters.sort',
  'category_filters.category_id.id',
  'category_filters.category_id.display_name',
  'category_filters.category_id.slug',
  'default_sort',
  'enable_cart',
  'filters',
  'manufacturer_filters.sort',
  'manufacturer_filters.manufacturer_id.id',
  'manufacturer_filters.manufacturer_id.display_name',
  'manufacturer_filters.manufacturer_id.slug',
  'manufacturer_filters.manufacturer_id.categories.category_id.id',
  'manufacturer_filters.manufacturer_id.categories.category_id.display_name',
  'manufacturer_filters.manufacturer_id.categories.category_id.slug',
  'maintenance_mode',
  'payment_methods.payment_id.id',
  'payment_methods.payment_id.display_name',
  'present_wrapping',
  'shipping_methods.shipping_id.id',
  'shipping_methods.shipping_id.display_name',
  'shipping_methods.shipping_id.free',
  'show_stock',
  'allow_ratings',
  'purchase_to_order',
  'subcategory_filters.subcategory_id.id',
  'subcategory_filters.subcategory_id.display_name',
  'subcategory_filters.subcategory_id.slug',
  'subcategory_filters.subcategory_id.category.id',
  'subcategory_filters.subcategory_id.category.display_name',
  'subcategory_filters.subcategory_id.category.slug',
  'attribute_filters.attribute_group_id.id',
  'attribute_filters.attribute_group_id.display_name',
  'attribute_filters.attribute_group_id.category.id',
  'attribute_filters.attribute_group_id.category.display_name',
  'attribute_filters.attribute_group_id.category.slug',
  'shipping_cost',
  'shipping_countries.country_id.id',
  'shipping_countries.country_id.display_name',
  'shipping_countries.country_id.country_code',
  'legal_pages.type',
  'legal_pages.content',
  'bank_details',
  'paypal_client_id',
  'default_tax_percent'
];

export const shippingFields = [
  'shipping_methods_config.shipping_id.name',
  'shipping_methods_config.shipping_id.shipping_provider',
  'shipping_methods_config.shipping_id.price_brutto',
  'shipping_methods_config.shipping_id.payment_methods.payment_method_id.id',
  'shipping_methods_config.shipping_id.payment_methods.payment_method_id.method',
  'shipping_methods_config.shipping_id.payment_methods.payment_method_id.config',
  'shipping_methods_config.shipping_id.additional_days',
  'shipping_methods_config.shipping_id.shipping_countries.country_id.display_name',
  'shipping_methods_config.shipping_id.shipping_countries.country_id.country_code',
];

export const productsFields = [
  'id',
  'display_name',
  'slug',
  'images.file_id.private_hash',
  'manufacturer.id',
  'manufacturer.display_name',
  'manufacturer.slug',
  'category.id',
  'category.display_name',
  'category.slug',
  'stock',
  'product_variants.price_brutto',
  'ratings.rating',
  'price_brutto',
]

export const productsMetaFields = [
  'result_count',
  'filter_count',
  'limit',
  'offset',
  'page',
  'page_count',
  'links.*',
]

export const productFields = [
  'id',
  'display_name',
  'slug',
  'reference_number',
  'description',
  'images.file_id.private_hash',
  'images.file_id.width',
  'images.file_id.height',
  'manufacturer.id',
  'manufacturer.display_name',
  'manufacturer.slug',
  'category.id',
  'category.display_name',
  'category.slug',
  'attributes.attribute_group.id',
  'attributes.attribute_group.display_name',
  'attributes.display_values',
  'tags',
  'price_brutto',
  'reorder_possible',
  'stock',
  'shipping_time',
  'ratings.rating',
  'variant_display_name',
  'product_variants.id',
  'product_variants.display_name',
  'product_variants.price_brutto',
  'product_variants.stock',
  'product_variants.reference_number_suffix',
  'product_variants.product',
  'product_variants.active',
  'product_variants.shipping_time',
  'product_variants.reorder_possible',
  'personalization.id',
  'personalization.display_name',
  'personalization.fields',
  'size_guide.display_name',
  'size_guide.table',
]