import React                                   from 'react';
import { ReactQueryDevtools }                  from 'react-query-devtools';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';

import { defaultQueryConfig } from '../config';
import { EApiProvider }       from '../context/EApi';
import { StoreProvider }      from '../context/Store';
import { CheckoutProvider }   from '../context/Checkout';
import CartPortal             from './CartIcon/CartIconPortal';
import ShopNavPortal          from './ShopNav/ShopNav';

const queryCache = new QueryCache(defaultQueryConfig);

const Root = ({ children }) => {
  return (
    <StoreProvider>
      <EApiProvider>
        <CheckoutProvider>
          <ReactQueryCacheProvider queryCache={queryCache}>
            <>
              <ReactQueryDevtools/>
              <CartPortal/>
              <ShopNavPortal />
              {children}
            </>
          </ReactQueryCacheProvider>
        </CheckoutProvider>
      </EApiProvider>
    </StoreProvider>
  )
};

export default Root;