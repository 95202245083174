import styled     from '@emotion/styled';
import { darken } from 'polished';

export const Sidebar = styled.div`
  height: 100%;
  background-color: #fff;
  overflow: auto !important;
  > ul {
    position: relative;
    height: 80vh;
    overflow: auto;
  }
  ul {
    list-style-type: none;
    margin: 0;
    background-color: #fff;
    li {
      margin: 0;
      padding: 0;
      .subnav {
        a {
          padding-left: 80px;
          padding-right: 20px;
        }
      }
      a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 20px 20px 20px 40px;
        transition: background-color 0.2s;
        text-decoration: none;
        color: unset;
        &:hover, 
        &.current {
          color: ${props => props.theme.brand_color};
          background-color: ${props => darken(0.1, '#fff')};
          font-weight: bold;
          &::after {
          content: '';
          display: block;
          width: 10%;
          height: 3px;
          background-color: ${props => props.theme.brand_color};
          }
        }
      }
    }
  }
`;