import React, { useState }            from 'react';
import PropTypes                      from 'prop-types';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { IoMdArrowDropdown }          from 'react-icons/io';
import Portal                         from '@reach/portal';
import AnimateHeight                  from 'react-animate-height';

import { NGroups } from './styles';

import Link     from '../../elements/Link/Link';
import Expander from '../Expander/Expander';

const SubNav = ({ sub, onLinkClick }) => {
  return (
    <ul className='subnav'>
      {sub.map(subpage => (
        <li key={subpage.displayName}>
          <Link activeClassName={'current'} className={subpage.type ? subpage.type : null}
                to={subpage.path} anchor={subpage.anchor} onClick={onLinkClick}>{subpage.displayName}</Link>
        </li>
      ))}
    </ul>
  )
};

const Groups = ({ groups, isOpen, onLinkClick, renderGroupsInPortal }) => {
  if (renderGroupsInPortal) {
    return (
      <Portal>
        <AnimateHeight duration={200} height={isOpen ? 'auto' : 0}
                       style={{ position: 'absolute', top: '170px', left: 0, right: 0, zIndex: 1 }}>
          <NGroups className='int-groups' visible={isOpen}>
            {groups.map(group => (
              <div key={group.displayName} className='int-group'>
                <p>{group.displayName}</p>
                <SubNav sub={group.sub} onLinkClick={onLinkClick}/>
              </div>
            ))}
          </NGroups>
        </AnimateHeight>
      </Portal>
    )
  } else {
    return (
      <AnimateHeight duration={200} height={isOpen ? 'auto' : 0}>
        <div className='int-groups-inline'>
          {groups.map(group => (
            <div key={group.displayName} className='int-group-inline'>
              {renderGroupsInPortal && <p>{group.displayName}</p>}
              <Expander
                title={group.displayName}
                close={!isOpen}
              >
                <SubNav sub={group.sub} onLinkClick={onLinkClick}/>
              </Expander>
            </div>
          ))}
        </div>
      </AnimateHeight>
    )
  }
};

function MenuStructure({ menu, renderGroupsInPortal }) {
  const [groupOpen, setGroupOpen] = useState({ open: {} });

  const toggleGroup = (i) => {
    setGroupOpen(prevState => {
      const open = { ...prevState.open };
      Object.keys(open).forEach(v => {
        v = parseInt(v);
        if (v !== i) {
          delete open[v];
        }
      });
      open[i] = !open[i];
      return { open };
    })
  };

  const closeGroups = () => {
    const open = { ...groupOpen };
    Object.keys(open).forEach(v => {
      v = parseInt(v);
      return open[v] = false;
    });
    setGroupOpen({ open: open })
  };

  const Chevron = ({ direction }) => {
    if (direction === 'up') {
      return <FaChevronUp/>
    } else {
      return <FaChevronDown/>
    }
  };

  return (
    <>
      <ul>
        {menu.map((page, i) => {
          const hasGroup = !page.path && page.groups && page.groups.length > 0;
          return (
            <li key={page.displayName}>
              {hasGroup
                ?
                <button style={{ cursor: 'pointer' }} className={groupOpen.open[i] ? 'active' : ''}
                        onClick={() => toggleGroup(i)}>{page.displayName} <Chevron
                  direction={groupOpen.open[i] ? 'up' : 'down'}/>
                </button>
                : <Link activeClassName={'current'} anchor={page.anchor} className={page.type ? page.type : null} to={page.path}
                        partiallyActive={page.path !== '/'} onClick={closeGroups}>{page.displayName} {page.sub &&
                <IoMdArrowDropdown/>}</Link>
              }
              {page.sub &&
              <SubNav sub={page.sub} onLinkClick={closeGroups}/>
              }
              {page.groups &&
              <Groups groups={page.groups} isOpen={groupOpen.open[i]} renderGroupsInPortal={renderGroupsInPortal}
                      onLinkClick={closeGroups}/>
              }
            </li>
          );
        })}
      </ul>
    </>
  )
}

MenuStructure.propTypes = {
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      path: PropTypes.string,
      sub: PropTypes.arrayOf(
        PropTypes.shape({
          displayName: PropTypes.string.isRequired,
          path: PropTypes.string.isRequired,
        })
      )
    })
  ),
  renderGroupsInPortal: PropTypes.bool
};

MenuStructure.defaultProps = {
  renderGroupsInPortal: true,
};

export default MenuStructure;
