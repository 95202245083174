import React, { useContext } from 'react';
import { FaShoppingBasket }  from 'react-icons/fa';
import ReactTooltip          from 'react-tooltip';
import { Link }              from 'gatsby';
import styled                from '@emotion/styled';

import { StoreContext } from '../../context/Store';

const Indicator = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #e74c3c;
  position: absolute;
  bottom: -3px;
  right: -2px;
`;

const CartIcon = () => {
  const { cart, storeRootPath } = useContext(StoreContext);
  if (!storeRootPath) {
    return null;
  }
  return (
    <>
      <Link style={{ position: 'relative' }} to={`${storeRootPath}/warenkorb`}>
        <FaShoppingBasket data-tip={`Warenkorb (${cart.totalCount})`}/>
        {cart.totalCount > 0 && <Indicator/>}
      </Link>
      <ReactTooltip/>
    </>
  );
}

export default CartIcon;