import React                               from 'react';
import { useStaticQuery, graphql }         from 'gatsby';
import { FormattedMessage, FormattedTime } from 'react-intl';
import { sortBy }                          from 'lodash';

import { findT } from '../../_helpers';

const OpeningPeriods = ({ openingPeriods, translations }) => {
  const dayEnum = {
    '0': 'Info',
    '1': 'Montag',
    '2': 'Mo - Mi',
    '3': 'Mo - Do',
    '4': 'Mo - Fr',
    '5': 'Dienstag',
    '6': 'Di - Fr',
    '7': 'Mittwoch',
    '8': 'Donnerstag',
    '9': 'Freitag',
    '10': 'Samstag',
    '11': 'Sonntag',
    '12': 'Mo - Di',
    '13': 'Do - Fr',
    '14': 'Mo, Mi, Fr',
    '15': 'Heiligabend',
    '16': 'Silvester',
  }
  let sortedPeriods = {};
  openingPeriods.forEach(period => {
    if (period.day !== '0') {
      if (period.time_open && period.time_close) {
        if (sortedPeriods[period.day]) {
          sortedPeriods[period.day].times.push(period.time_open, period.time_close)
        } else {
          sortedPeriods[period.day] = {
            day: period.day,
            times: [period.time_open, period.time_close],
            sort: period.sort
          }
        }
      } else {
        sortedPeriods[period.day] = {
          day: period.day,
          closed: true,
          sort: period.sort
        }
      }
    }
  })
  let array = [];
  Object.keys(sortedPeriods).forEach(period => array.push(sortedPeriods[period]));
  const sortedArray = array.sort((a, b) => a.sort - b.sort);
  //console.log(sortedPeriods.sort((a, b) => a.sort - b.sort));
  const freeText = openingPeriods.find(period => period.free_text);
  return (
    <>
      <h4>{translations.header
        ? translations.header
        : <FormattedMessage id='components.widgets.openingHoursWidget.defaultHeader'/>}
      </h4>
      <ul style={{maxWidth: '320px'}}>
        {sortedArray.map(period => {
          const custom = !Object.keys(dayEnum).find(d => d === period.day);
          return <li key={period.day} style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}>
            <span style={{ marginRight: '5px' }}>{custom ? period.day : dayEnum[period.day]}</span>
            {period.closed
              ? <FormattedMessage id='components.widgets.openingHoursWidget.closed'/>
              : <span>{period.times.map((time, i) => {
                return (
                  <React.Fragment key={time + i}>
                    <time>{time.substring(0, time.length - 3)}</time>
                    {i % 2 ? null : ' - '}
                    {i % 2 ? <br/> : null}
                  </React.Fragment>
                )
              })}
              </span>
            }
          </li>
        })}
        {freeText ? <li className='int-free-text' style={{
          display: 'flex',
        }}>{freeText.free_text}</li> : null}
      </ul>
    </>
  )
}

const OpeningHours = ({ openingHours, translations, lang }) => {
  const sortedOpeningHours = sortBy(openingHours, oh => oh.sort);
  return (
    <>
      <h4>{translations.header
        ? translations.header
        : <FormattedMessage id='components.widgets.openingHoursWidget.defaultHeader'/>}
      </h4>
      <ul>
        {sortedOpeningHours.map(oh => {
          const translations = findT(oh.translations, lang);
          if (translations.free_text) {
            return (
              <li key={oh.sort}>
                <p>{translations.free_text}</p>
              </li>
            )
          } else if (!oh.closed) {
            const openingDateTime = new Date(`1970-01-01T${oh.opening_time}Z`.replace(/-/g, '/').replace(/[T|Z]/g, ' '));
            const closingDateTime = new Date(`1970-01-01T${oh.closing_time}Z`.replace(/-/g, '/').replace(/[T|Z]/g, ' '));
            return (
              <li key={oh.sort}>{translations.day}: <time><FormattedTime value={openingDateTime}/></time> - <time>
                <FormattedTime
                  value={closingDateTime}/></time> {lang === 'de' && <FormattedMessage
                id='components.widgets.openingHoursWidget.time'/>}</li>
            )
          } else {
            return (
              <li key={oh.sort}>{translations.day}: <FormattedMessage
                id='components.widgets.openingHoursWidget.closed'/></li>
            )
          }
        })}
      </ul>
    </>
  )
}

const OpeningHoursWidget = props => {
  const translations = findT(props.widget.translations, props.lang);
  const data = useStaticQuery(query);
  const openingPeriods = data.allDirectusOpeningPeriods.nodes;
  const openingHours = data.allDirectusOpeningHours.nodes;

  return openingPeriods.length > 0
    ? <OpeningPeriods openingPeriods={openingPeriods} translations={translations}/>
    : <OpeningHours openingHours={openingHours} translations={translations} lang={props.lang}/>

};

const query = graphql`
  query {
    allDirectusOpeningHours(filter: {id: {ne: "dummy"}}) {
      nodes {
        ...OpeningHours
      }
    }
    allDirectusOpeningPeriods(filter: {id: {ne: "dummy"}}) {
      nodes {
        open
        sort
        time_open
        time_close
        day
        free_text
      }
    }
  }
`;

export default OpeningHoursWidget;
