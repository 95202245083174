import React                    from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { findT }            from '../../_helpers';
import { FormattedMessage } from 'react-intl';

const AddressWidget = props => {
  let showLogo;
  const defaultProps = {
    showLogo: false,
  }
  const translations = findT(props.widget.translations, props.lang);
  if (!props.widget.props) {
    showLogo = defaultProps.showLogo
  } else {
    showLogo = props.widget.props.showLogo;
  }
  return <StaticQuery query={query} render={data => {
    const address = data.directusCompany.addresses[0];
    const name = data.directusCompany.display_name;
    return (
      <>
        {showLogo && <img style={{
          maxWidth: '100%',
          maxHeight: '80px',
          margin: '0',
        }} src={data.directusProject.logo.localFile.publicURL} alt={name} title={name}/>}
        {!showLogo && <h4>{translations.header
          ? translations.header
          : <FormattedMessage id="components.widgets.addressWidget.defaultHeader"/>}
        </h4>}
        <address>
          <ul>
            <li>{name}</li>
            <li>{address.street} {address.number}</li>
            <li>{address.zip_code} {address.city}</li>
          </ul>
        </address>
      </>
    )
  }}/>
};

const query = graphql`
    query {
        directusProject {
            ...ProjectLogoWithSvg
        }
        directusCompany {
            company_name
            display_name
            addresses {
                street
                number
                zip_code
                city
            }
        }
    }
`;

export default AddressWidget;
