import React      from 'react';
import PropTypes  from 'prop-types';
import { sortBy } from 'lodash';

import AddressWidget      from './AddressWidget';
import ContactWidget      from './ContactWidget';
import NavigationWidget   from './NavigationWidget';
import OpeningHoursWidget from './OpeningHoursWidget';
import SocialWidget       from './SocialWidget';

function Widget(props) {
  switch (props.widget.widget_name) {
    case 'address':
      return <AddressWidget widget={props.widget} lang={props.lang}/>;
    case 'contact':
      return <ContactWidget widget={props.widget} lang={props.lang}/>;
    case 'navigation':
      return <NavigationWidget widget={props.widget} lang={props.lang}/>;
    case 'social':
      return <SocialWidget widget={props.widget} lang={props.lang}/>;
    case 'openingHours':
      return <OpeningHoursWidget widget={props.widget} lang={props.lang}/>;
    default:
      return <p>Widget not found :-(</p>;
  }
}

function Widgets(props) {
  const { widgets, lang } = props;
  const sorted = sortBy(widgets, widget => widget.sort);
  return (
    sorted.map(widget => <div key={widget.sort}><Widget widget={widget} lang={lang}/></div>)
  );
}

Widgets.propTypes = {
  widgets: PropTypes.array.isRequired,
  lang: PropTypes.string.isRequired,
};

export default Widgets;
