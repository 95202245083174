import React                    from 'react';
import ReactTooltip             from 'react-tooltip';
import {
  FaFacebook,
  FaExternalLinkAlt,
  FaInstagram
}                               from 'react-icons/fa';
import { IconButton, useTheme } from '@chakra-ui/react';
import { readableColor }        from 'polished';

import * as S                    from './styles';
import { findT }                 from '../../_helpers';
import { useSocialMediaBarData } from '../../../hooks';
import Wrapper                   from '../../structure/Wrapper/Wrapper';

function mediaRenderer(network) {
  const theme = useTheme();
  const color = readableColor(theme.colors.brand['500']);
  switch (network) {
    case 'facebook':
      return <IconButton
        aria-label="Facebook"
        size="lg"
        colorScheme="brand" icon={<FaFacebook size="1.2rem" color={color}/>}/>;
    case 'instagram':
      return <IconButton
        aria-label="Instagram"
        size="lg"
        colorScheme="brand" icon={<FaInstagram size="1.2rem" color={color}/>}/>;
    default:
      return <IconButton
        aria-label="Social Media"
        size="lg"
        colorScheme="brand" icon={<FaExternalLinkAlt size="1.2rem" color={color}/>}/>;
  }
}

function SocialMediaBar() {
  const data = useSocialMediaBarData();
  const networks = data.networks && findT(data.networks.translations, data.language).config;
  const networksNew = data.networksNew ? data.networksNew.config : null;
  if (networks) {
    return (
      <Wrapper>
        <nav>
          <S.Bar count={networks.length}>
            {/*eslint-disable-next-line*/}
            {networks.map((network) => {
              if (network.displayName !== '') {
                return (
                  <li key={network.media}>
                    <a href={network.path} title={network.displayName} target="_blank" rel="noopener noreferrer"
                       data-tip={network.displayName}>
                      {mediaRenderer(network.media)}
                    </a>
                    <ReactTooltip delayShow={200}/>
                  </li>
                );
              }
            })}
          </S.Bar>
        </nav>
      </Wrapper>
    )
  } else if (networksNew) {
    return (
      <nav>
        <S.Bar count={networksNew.length}>
          {/*eslint-disable-next-line*/}
          {networksNew.map((network) => {
            if (network.displayName !== '') {
              return (
                <li key={network.media}>
                  <a href={network.external_path} title={network.displayName} target="_blank" rel="noopener noreferrer"
                     data-tip={network.displayName}>
                    {mediaRenderer(network.media)}
                  </a>
                  <ReactTooltip delayShow={200}/>
                </li>
              );
            }
          })}
        </S.Bar>
      </nav>
    )
  } else {
    return null;
  }
}


export default SocialMediaBar;
