import React                from 'react';
import { find }             from 'lodash';
import { FormattedMessage } from 'react-intl';

import Spacer             from '../../structure/Spacer/Spacer';
import PhoneNumber        from '../../text/PhoneNumber/PhoneNumber';
import { ContactContext } from '../../../providers';

import * as S from './styles';

export const legalRenderer = (form) => {
  switch (form) {
    case 'single':
      return <FormattedMessage id='components.templates.legalSidebar.owner'/>;
    case 'ev':
      return 'Vertretungsberechtigte Vorstände:';
    case 'bag':
      return 'Berufsausübungsgemeinschaft der Vertragsärzte'
    default:
      return <FormattedMessage id='components.templates.legalSidebar.representedBy'/>;
  }
};

export const addressTypeRenderer = (type) => {
  switch (type) {
    case 'hq':
      return 'Hauptgeschäftsstelle';
    case 'federal_office':
      return 'Bundesgeschäftsstelle';
    default:
      return;
  }
};

function LegalSidebar() {
  return (
    <ContactContext.Consumer>
      {context => {
        const address = context.addresses[0];
        const email = find(context.emails, email => email.primary);
        const phoneNumber = find(context.numbers, number => (number.type === 'phone' || number.type === 'mobile') && number.primary);
        const faxNumber = find(context.numbers, number => number.type === 'fax' && number.primary);
        return (
          <S.Aside>
            <section>
              <h4>{context.company_name}</h4>
              {address.address_type && <i>{addressTypeRenderer(address.address_type)}</i>}
              <Spacer height={10}/>
              <p>{address.street} {address.number}</p>
              <p>{address.zip_code} {address.city}</p>
              <Spacer height={10}/>
              <b>{legalRenderer(context.legal_form)}</b>
              {context.executives.map(({ name }) => <div
                key={name}>{name}</div>)}
            </section>
            <section>
              <h4><FormattedMessage id='components.templates.legalSidebar.contactHeader'/></h4>
              {phoneNumber && <p><FormattedMessage id='components.templates.legalSidebar.tel'/> <PhoneNumber
                number={phoneNumber.number}
                callable={false}/></p>}
              {faxNumber &&
              <p><FormattedMessage id='components.templates.legalSidebar.fax'/> <PhoneNumber number={faxNumber.number}
                                                                                             title={'Fax'}
                                                                                             callable={false}/></p>}
              {email && <p><FormattedMessage id='components.templates.legalSidebar.email'/> {email.email}</p>}
            </section>
            {context.registers.map(register => (
              <section key={register.register_type}>
                <h4>{register.register_type}</h4>
                <p>{register.number}</p>
                <p>{register.registrar}</p>
              </section>
            ))}
            {context.ust_id &&
            <section>
              <h4><FormattedMessage id='components.templates.legalSidebar.ustHeader'/></h4>
              <p><FormattedMessage id='components.templates.legalSidebar.ustDescr'/> <span
                style={{ whiteSpace: 'nowrap' }}>{context.ust_id}</span></p>
            </section>}
            {context.vat_number &&
            <section>
              <h4><FormattedMessage id='components.templates.legalSidebar.tax'/></h4>
              <p><FormattedMessage id='components.templates.legalSidebar.tax'/> {context.vat_number}</p>
            </section>}
          </S.Aside>
        );
      }}
    </ContactContext.Consumer>
  )
}

export default LegalSidebar;
