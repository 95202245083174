import React, { useContext } from 'react';
import { FormattedMessage }  from 'react-intl';
import { Button }            from '@chakra-ui/react';

import MenuStructure     from '../../structure/MenuStructure/MenuStructure';
import { findT }         from '../../_helpers';
import { GlobalContext } from '../../../providers';

const NavigationWidget = props => {
  const { setShowCookieBanner } = useContext(GlobalContext);
  const translations = findT(props.widget.translations, props.lang);

  const defaultMenu = [
    {
      displayName: 'Impressum',
      path: '/impressum'
    },
    {
      displayName: 'Datenschutz',
      path: '/datenschutz'
    }
  ]

  return (
    <>
      <h4>{translations.header
        ? translations.header
        : <FormattedMessage id="components.widgets.navigationWidget.defaultHeader"/>}
      </h4>
      <nav>
        <MenuStructure menu={defaultMenu}/>
        <div id="int-shop-menu"/>
      </nav>
      <Button onClick={() => setShowCookieBanner(true)} variant="link" color="unset" textDecoration="underline"
              fontWeight="300">
        Cookie Einstellungen
      </Button>
    </>
  )
};

export default NavigationWidget;
