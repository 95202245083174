import React, { useContext }       from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { LanguageContext }   from '../../providers';
import { findT }             from '../../components/_helpers';
import Spacer                from '../../components/structure/Spacer/Spacer';
import ContentConsentManager from '../../components/modules/ContentConsent/ContentConsentManager';

// TODO
const InfoBlock = ({ id }) => {
  const { language } = useContext(LanguageContext);
  const { directusLegal: legal, directusCompany: company } = useStaticQuery(query);
  if (legal || company) {
    const translations = legal && legal.translations.length > 0 ? findT(legal.translations, language) : null;
    switch (id) {
      case 'legal_authority':
        if (company.legal_authority) {
          return <>
            <div dangerouslySetInnerHTML={{ __html: company.legal_authority }}/>
            <Spacer/></>
        } else return null;
      case 'insurance':
        if (translations && translations.insurance) {
          return <>
            <h2>Berufshaftpflichtversicherung</h2>
            <div dangerouslySetInnerHTML={{ __html: translations.insurance }}/>
            </>
        } else return null;
      case 'image_sources':
        if (translations && translations.image_sources) {
          return <div dangerouslySetInnerHTML={{ __html: translations.image_sources }}/>
        } else return null;
      case 'additional_top_info':
        if (translations && translations.additional_top_info) {
          return <div dangerouslySetInnerHTML={{ __html: translations.additional_top_info }}/>
        } else {
          return null;
        }
      case 'additional_bottom_info':
        if (translations && translations.additional_bottom_info) {
          return <div dangerouslySetInnerHTML={{ __html: translations.additional_bottom_info }}/>
        } else {
          return null;
        }
      case 'data_protection':
        const dpc = legal ? legal.data_protection_commissioner[0] : null;
        if (dpc) {
          return (
            <>
              <h3>Gesetzlich vorgeschriebener Datenschutzbeauftragter</h3>
              <p>Wir haben für unser Unternehmen einen Datenschutzbeauftragten bestellt.</p>
              <b>{dpc.name}</b>
              <p>
                {dpc.street} {dpc.number} <br/>
                {dpc.city} {dpc.zip_code} <br/>
              </p>
              <p>
                Telefon: {dpc.phone_number} <br/>
                E-Mail: {dpc.email} <br/>
              </p>
            </>
          );
        } else {
          return null;
        }
      case 'rolex':
        if (legal && legal.rolex_area) {
          return <>
            <h3 id='rolex-bereich'>Rolex Bereich</h3>
            <p>Wenn Sie den Rolex Bereich unserer Website besuchen, interagieren Sie mit einer eingebetteten Website von
              <a
                href='https://www.rolex.com' target='_blank'
                rel='noopener noreferrer'>www.rolex.com</a>.
              In diesem Fall gelten einzig die <a href='https://www.rolex.com/de/legal-notices/terms-of-use.html'
                                                  target='_blank' rel='noopener noreferrer'>Nutzungsbedingungen</a>, <a
                href='https://www.rolex.com/de/legal-notices/privacy-notice.html' target='_blank'
                rel='noopener noreferrer'>Datenschutzhinweise</a> und <a
                href='https://www.rolex.com/de/legal-notices/cookies.html' target='_blank'
                rel='noopener noreferrer'>Cookie-Richtlinie</a> von  <a
                href='https://www.rolex.com' target='_blank'
                rel='noopener noreferrer'>www.rolex.com</a>.</p>
            <p>Cookie-Richtlinien von Rolex: <a target='_blank' rel='noopener noreferrer' href='https://www.rolex.com/legal-notices/cookies.html'>https://www.rolex.com/legal-notices/cookies.html</a> Datenschutzhinweise von Adobe Analytics: <a target='_blank' rel='noopener noreferrer' href='https://www.adobe.com/de/privacy/policy.html'>https://www.adobe.com/de/privacy/policy.html</a> Datenschutzhinweise von Content Square: <a target='_blank' rel='noopener noreferrer' href='https://contentsquare.com/de-de/privacy-center/'>https://contentsquare.com/de-de/privacy-center/</a></p>
          </>
        } else {
          return null;
        }
      case 'consentmanager':
        return <ContentConsentManager />
      default:
        return null;
    }
  } else return null;
};

export default InfoBlock;

const query = graphql`
  query {
    directusCompany(id: {ne: "dummy"}) {
      legal_authority
    }
    directusLegal(id: {ne: "dummy"}) {
      data_protection_commissioner {
        city
        email
        name
        number
        phone_number
        street
        zip_code
      }
      newsletter
      rolex_area
      translations {
        additional_bottom_info
        additional_top_info
        image_sources
        insurance
        language
      }
    }
  }
`;