import { calculateAverageRating, getVariantsPriceRange } from '../helpers';

export const setProductStructure = () => {
  return {
    details: null,
    hasVariants: false,
    variantDisplayName: '',
    variantsAvailable: [],
    variantSelected: null,
    variantPriceRange: [],
    shippingTime: null,
    reorderPossible: false,
    priceBrutto: null,
    stock: null,
    countSelected: null,
    personalization: null,
    ratingsCount: null,
    averageRating: null,
  }
}

export const productReducer = (state, action) => {
  let localState = { ...state };
  switch (action.type) {
    case 'add':
      const product = action.payload;
      localState.details = product;
      localState.ratingsCount = product.ratings.length;
      localState.averageRating = calculateAverageRating(product.ratings);
      if (product.product_variants.length > 0) {
        localState.hasVariants = true;
        localState.variantsAvailable = product.product_variants;
        localState.variantPriceRange = getVariantsPriceRange(product.product_variants);
        localState.variantDisplayName = product.variant_display_name || 'Variante';
      } else {
        localState.priceBrutto = product.price_brutto;
        localState.countSelected = 1;
        localState.stock = product.stock;
        localState.shippingTime = product.shipping_time;
        localState.reorderPossible = product.reorder_possible ? true : !product.stock <= 0;
      }
      break;
    case 'reset':
      localState = setProductStructure();
      break;
    case 'variantChange':
      // Check if Object is empty "{}" to set variantSelected to null
      const isEmpty = (Object.keys(action.payload).length === 0 && action.payload.constructor === Object)
      localState.variantSelected = isEmpty ? null : action.payload;
      if (!isEmpty) {
        localState.stock = action.payload.stock;
        localState.priceBrutto = action.payload.price_brutto;
        localState.countSelected = 1;
        localState.shippingTime = action.payload.shipping_time;
        localState.reorderPossible = action.payload.reorder_possible ? true : !action.payload.stock <= 0;
      } else {
        // Reset
        localState.countSelected = null;
        localState.stock = null;
        localState.priceBrutto = null;
        localState.shippingTime = null;
        localState.reorderPossible = false;
      }
      break;
    case 'countChange':
      localState.countSelected = action.payload;
      break;
    case 'personalizationChange':
      localState.personalization = action.payload;
      break;
    default:
      console.log('Product Reducer Action Type not found');
      break;
  }

  state = { ...localState };

  return state;
}
