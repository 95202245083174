import React, { useState }         from 'react';
import { useStaticQuery, graphql } from 'gatsby';

export const LanguageContext = React.createContext({});

export const LanguageProvider = ({ currentLang, children }) => {
  const languages = useStaticQuery(query).directusProject.languages.filter(el => el !== '');

  const [language, setCurrentLanguage] = useState(currentLang);
  const [availableLanguages] = useState(languages);

  return <LanguageContext.Provider
    value={{ language, setCurrentLanguage, availableLanguages }}>{children}</LanguageContext.Provider>
};

const query = graphql`
  query {
    directusProject {
      languages
    }
  }
`;
