export function findImage(images, imageName) {
  try {
    return images.find(image => image.name.toLowerCase().trim() === imageName.toLowerCase().trim()).childImageSharp;
  } catch (e) {
    console.log(e);
    console.log('---------------------------------');
    console.log('Failed at image: ', imageName);
    console.log('---------------------------------');
  }
}

export function findMedia(mediaCollection, mediaName) {
  try {
    return mediaCollection.find(media => media.file.localFile.name.toLowerCase().trim() === mediaName.toLowerCase().trim()).file.localFile;
  } catch (e) {
    console.log(e);
    console.log('---------------------------------');
    console.log('Failed at image: ', mediaName);
    console.log('---------------------------------');
  }
}

export function findAttributedMedia(mediaCollection, mediaName) {
  try {
    return mediaCollection.find(media => media.file.localFile.name.toLowerCase().trim() === mediaName.toLowerCase().trim());
  } catch (e) {
    console.log(e);
    console.log('---------------------------------');
    console.log('Failed at image: ', mediaName);
    console.log('---------------------------------');
  }
}

export function createSubNavigationStructure(brands, type, routes, lang) {
  const menu = [];
  brands.forEach(({brand}) => {
    if (brand.type.type === type) {
      menu.push({
        displayName: brand.display_name,
        path: `${routes[brand.type.type][lang]}/${brand.slug}`,
      })
    }
  });
  return menu;
}

export const isLinkExternal = link => /(http|https):\/\/(.*)/.test(link);

export const findT = (translations, language) => {
  const translation = translations.find(translation => translation.language === language);
  if (translation) {
    return translation;
  } else {
    console.log(`Translations (${language}) not found. Fell back to ${translations[0].language} version`);
    return translations[0];
  }
};

export const flattenMessages = ((nestedMessages, prefix = '') => {
  if (nestedMessages === null) {
    return {}
  }
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      Object.assign(messages, { [prefixedKey]: value })
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey))
    }

    return messages
  }, {})
});

export const getImageOrientation = (aspectRatio) => {
  let orientation;
  if (aspectRatio > 1) {
    orientation = 'landscape';
  } else if (aspectRatio < 1) {
    orientation = 'portrait';
  } else {
    orientation = 'square';
  }
  return orientation;
};