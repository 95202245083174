import React, { useState, useEffect } from 'react';

export const CheckoutContext = React.createContext({});

const LOCAL_STORAGE_KEY = 'int-checkout';

const saveToLocal = (state) => {
  window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ timestamp: new Date().valueOf(), ...state }));
}

const getFromLocal = () => {
  if (typeof window !== 'undefined') {
    return JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY))
  }
}

// TODO useReducer...
export const CheckoutProvider = ({ children }) => {

  const [customerEmail, setCustomerEmail] = useState(null);
  const [shippingAddress, setShippingAddress] = useState(null);
  const [invoiceAddress, setInvoiceAddress] = useState(null);
  const [comment, setComment] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [availableShippingMethods, setAvailableShippingMethods] = useState(null);
  const [shippingMethod, setShippingMethod] = useState(null);
  const [shippingCost, setShippingCost] = useState(0);
  const [presentWrap, setPresentWrap] = useState(false);
  const [payPal, setPayPal] = useState(null);

  const [mounted, setMounted] = useState(false);

  const resetAll = () => {
    window.localStorage.removeItem(LOCAL_STORAGE_KEY);
    setCustomerEmail(null);
    setShippingAddress(null);
    setInvoiceAddress(null);
    setComment(null);
    setPaymentMethod(null);
    setShippingMethod(null);
    setShippingCost(0);
    setPresentWrap(false);
  };

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
      const localState = getFromLocal();
      if (localState) {
        setCustomerEmail(localState.customerEmail);
        setShippingAddress(localState.shippingAddress);
        setInvoiceAddress(localState.invoiceAddress);
        setComment(localState.comment);
        setPaymentMethod(localState.paymentMethod);
        setShippingMethod(localState.shippingMethod);
        setShippingCost(localState.shippingCost);
        setPresentWrap(localState.presentWrap);
      }
    } else {
      saveToLocal({
        customerEmail,
        shippingAddress,
        invoiceAddress,
        comment,
        paymentMethod,
        shippingMethod,
        shippingCost,
        presentWrap
      })
    }
    //eslint-disable-next-line
  }, [customerEmail, shippingAddress, invoiceAddress, comment, paymentMethod, shippingMethod, presentWrap, shippingCost])

  return <CheckoutContext.Provider
    value={{
      customerEmail, setCustomerEmail,
      shippingAddress, setShippingAddress,
      invoiceAddress, setInvoiceAddress,
      comment, setComment,
      paymentMethod, setPaymentMethod,
      availableShippingMethods, setAvailableShippingMethods,
      shippingMethod, setShippingMethod,
      shippingCost, setShippingCost,
      presentWrap, setPresentWrap,
      payPal, setPayPal,
      resetAll,
    }}>{children}</CheckoutContext.Provider>
};
