import React                   from 'react';
import { IntlProvider }        from 'react-intl'
import { Helmet }              from 'react-helmet';
import { BreakpointProvider }  from 'react-socks';
import { ScreenClassProvider } from 'react-grid-system';

import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/de'

import de from '../../locales/de/messages.json';
import en from '../../locales/en/messages.json';
import fr from '../../locales/fr/messages.json';

import { flattenMessages }                   from '../_helpers';
import SEO                                   from '../../components/modules/SEO/SEO';
import { LanguageProvider, ContactProvider } from '../../providers';
import { GlobalProvider }                    from '../../providers';

const messages = { de, en, fr };


const BaseLayout = ({ children, pageContext, locale }) => {
  const lang = pageContext ? pageContext.lang : locale
  return (
    <IntlProvider locale={lang} defaultLocale={'de'} messages={flattenMessages(messages[lang])}>
      <LanguageProvider currentLang={lang}>
        <ContactProvider>
          <GlobalProvider>
            <SEO/>
            <Helmet>
              <meta http-equiv='X-UA-Compatible' content='IE=edge'/>
              {/*Prevent Safari from detecting phone numbers*/}
              <meta name='format-detection' content='telephone=no'/>
            </Helmet>
            <ScreenClassProvider>
              <BreakpointProvider>
                {children}
              </BreakpointProvider>
            </ScreenClassProvider>
          </GlobalProvider>
        </ContactProvider>
      </LanguageProvider>
    </IntlProvider>
  )
};

export default BaseLayout;
