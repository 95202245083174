import React                from 'react';
import PropTypes            from 'prop-types';
import { parsePhoneNumber } from 'libphonenumber-js';

import { Link } from '../../elements';

export default function PhoneNumber(props) {
  let phoneNumber;
  try {
    phoneNumber = parsePhoneNumber(props.number, props.country);
  } catch (e) {
    console.log(`${props.number} is not a valid number`)
  }

  if (!phoneNumber) {
    return <>{props.number}</>
  }

  if (props.callable) {
    return <Link to={phoneNumber.getURI()} external title='Telefon'>{phoneNumber.formatInternational()}</Link>
  } else {
    return <>{phoneNumber.formatInternational()}</>
  }
}

PhoneNumber.propTypes = {
  number: PropTypes.string.isRequired,
  country: PropTypes.string,
  callable: PropTypes.bool,
  title: PropTypes.string,
};

PhoneNumber.defaultProps = {
  country: 'DE',
  callable: true,
  title: 'Telefon',
};