import styled            from '@emotion/styled';
import { readableColor } from 'polished';

export const Banner = styled.div`
  width: 520px;
  z-index: 10001;
  position: fixed;
  bottom: 30px;
  left: 30px;
  background: ${props => props.theme.base_color};
  color: ${props => readableColor(props.theme.base_color)};
  border-radius: 3px;
  box-shadow: 0 6px 20px rgba(0,0,0,0.2);
  padding: 20px;
  @media (max-width: 1260px) {
    right: 30px;
    left: auto;
  }
  @media (max-width: 700px) {
    width: 95%;
    left: 0;
    right: 0;
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
    margin: auto;
  }
  h3 {
    margin: 0;
  }
  h3::after {
    content: " ";
    height: 5px;
    width: 60px;
    background-color: ${props => props.theme.brand_color};
    display: block;
    margin-top: 5px;
  }
  .int-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    > button {
      margin-left: 10px;
    }
  }
`;