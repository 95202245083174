import React, { useEffect, useContext } from 'react';
import { Helmet }                       from 'react-helmet';
import { graphql, useStaticQuery }      from 'gatsby';
import DirectusSDK                      from '@directus/sdk-js';

import {
  productsFields,
  productFields,
  productsMetaFields,
  settingsFields,
  endpoints,
  shippingFields,
}                       from '../config';
import { StoreContext } from './Store';

export const EApiContext = React.createContext({});

export const EApiProvider = ({ children }) => {
  const { directusProject } = useStaticQuery(query);
  const { settings, setSettings, setProjectName } = useContext(StoreContext);
  const domain = process.env.GATSBY_SHOP_PROJECT ? process.env.GATSBY_SHOP_PROJECT : directusProject.display_name;
  const client = new DirectusSDK({
    url: endpoints.api,
    project: endpoints.project,
  })

  const fetchSettings = async () => {
    try {
      const settings = await client.getItems('ecommerce_settings', {
        'filter[project.domain]': domain,
        fields: settingsFields.toString(),
        single: 1
      });

      if (settings.data) {
        setSettings({
          loading: false,
          data: settings.data,
          error: null
        })
      } else {
        setSettings({
          data: null,
          loading: false,
          error: 'Could not load settings'
        })
      }
    } catch (e) {
      setSettings({
        data: null,
        loading: false,
        error: 'Could not load settings'
      })
      console.log(e)
    }
  }

  const fetchShipping = async () => {
    try {
      const shipping = await client.getItems('ecommerce_settings', {
        'filter[project.domain]': domain,
        fields: shippingFields.toString(),
        single: 1
      });

      if (shipping.data) {
        return shipping.data.shipping_methods_config;
      } else {
        return (shipping.error)
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  const fetchProducts = async (name, args, page) => {
    const {
      categoryFilter,
      manufacturersFilter,
      subcategoriesFilter,
      tagFilter,
      sort,
    } = args;

    const filters = {}

    if (categoryFilter && typeof categoryFilter !== 'string') {
      filters.category = categoryFilter;
    } else if (categoryFilter && typeof categoryFilter === 'string') {
      filters['category.slug'] = categoryFilter;
    }

    if (manufacturersFilter.length > 0 && typeof manufacturersFilter !== 'string') {
      filters.manufacturer = {
        in: await manufacturersFilter.map(m => m.id).toString(),
      }
    } else if (manufacturersFilter && typeof manufacturersFilter === 'string') {
      filters['manufacturer.slug'] = manufacturersFilter;
    }

    if (subcategoriesFilter.length > 0) {
      filters['subcategories.subcategory_id'] = {
        in: await subcategoriesFilter.map(m => m.id).toString(),
      }
    }

    if (typeof tagFilter !== 'undefined' && tagFilter !== '') {
      filters['tags'] = {
        contains: tagFilter
      }
    }

    try {
      const res = await client.getItems('ecommerce_product', {
        filter: {
          'project.domain': domain,
          status: 'published',
          ...filters
        },
        sort: sort,
        page: page + 1,
        limit: 20,
        fields: productsFields.toString(),
        meta: productsMetaFields.toString(),
      })

      if (res.data) {
        return res;
      } else {
        return (res.error)
      }

    } catch (e) {
      throw new Error(e)
    }
  }

  const fetchRandomProducts = async (name, args) => {
    const { limit } = args;

    try {
      const res = await client.getItems('ecommerce_product', {
        filter: {
          'project.domain': domain,
          status: 'published',
        },
        sort: '?',
        limit: limit,
        fields: productsFields.toString(),
        meta: productsMetaFields.toString(),
      })

      if (res.data) {
        return res;
      } else {
        return res.error
      }

    } catch (e) {
      throw new Error(e)
    }
  }

  const fetchProduct = async (name, args) => {
    try {
      const { id } = args;
      const res = await client.getItems('ecommerce_product', {
        filter: {
          id: id,
          'project.domain': domain,
        },
        single: 1,
        fields: productFields.toString(),
      });

      if (res.data) {
        return res;
      } else {
        return (res.error)
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  const fetchProductRatings = async (name, args) => {
    const { id } = args;
    try {
      const res = await client.getItems('ecommerce_product_rating', {
        filter: {
          'product.id': id
        }
      })
      if (res.data) {
        return res;
      } else {
        return (res.error)
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  const fetchAttributes = async (name, args) => {
    try {
      const { ids } = args;
      const res = await client.getItems('ecommerce_attribute', {
        'filter[attribute_group][in]': ids,
        limit: -1,
      });
      if (res.data) {
        return res;
      } else {
        return (res.error)
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  const fetchPrices = async () => {
    try {
      const res = await client.getItems('ecommerce_product', {
        filter: {
          'project.domain': domain,
          status: 'published',
        },
        limit: -1,
        fields: 'price_brutto',
      });
      if (res.data) {
        return res.data.map(p => p.price_brutto);
      } else {
        return (res.error)
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  const onPayPalTransaction = async (orderId, project, paypalDetails) => {
    const body = {
      project,
      order_id: orderId,
      paypal: paypalDetails
    }
    let transactionReq;
    try {
      transactionReq = await fetch(`${endpoints.orderApi}/ecom/order/paypal_transaction`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });
    } catch (e) {
      console.log(e);
      return {
        error: 'Could not post PayPal Transaction'
      }
    }

    const res = await transactionReq.json();

    if (res.status === 'ok') {
      return res;
    } else {
      return {
        error: 'Could not post PayPal Transaction'
      };
    }
  };

  const createOrder = async (order) => {
    let orderReq;
    try {
      orderReq = await fetch(`${endpoints.orderApi}/ecom/order`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(order)
      });
    } catch (e) {
      console.log(e);
      return {
        error: 'Could not post order'
      }
    }

    const res = await orderReq.json();

    if (res.status === 'ok') {
      return res;
    } else {
      return {
        error: 'Could not post order'
      };
    }
  }

  const placeKlarnaOrder = async (token, orderUuid) => {
    let orderReq;
    try {
      orderReq = await fetch(`${endpoints.orderApi}/ecom/klarna/order`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          project: domain,
          authorization_token: token,
          order_uuid: orderUuid
        })
      });
    } catch (e) {
      console.log(e);
      return {
        error: 'Could not place klarna order'
      }
    }

    const res = await orderReq.json();

    if (res.message === 'ok') {
      return res;
    } else {
      return {
        error: 'Could not post order'
      };
    }
  }

  const createRequest = async (request) => {
    let reqReq;
    const body = {
      project: domain,
      ...request
    }
    try {
      reqReq = await fetch(`${endpoints.orderApi}/ecom/request`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });
    } catch (e) {
      console.log(e);
      return {
        error: 'Could not post request'
      }
    }

    const res = await reqReq.json();

    if (res.status === 'ok') {
      return res;
    } else {
      return {
        error: 'Could not post request'
      }
    }

  }

  const createProductRating = async (rating) => {
    let ratingReq;
    const body = {
      project: domain,
      ...rating,
    }
    try {
      ratingReq = await fetch(`${endpoints.orderApi}/ecom/rating`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });
    } catch (e) {
      console.log(e);
      return {
        error: 'Could not post rating'
      }
    }

    const res = await ratingReq.json();

    if (res.status === 'ok') {
      return res;
    } else {
      return {
        error: 'Could not post rating'
      }
    }
  }

  useEffect(() => {
    if (!settings.data) {
      fetchSettings();
    }
    setProjectName(domain);
    //eslint-disable-next-line
  }, [settings.data]);

  return <EApiContext.Provider value={{
    fetchShipping,
    fetchProducts,
    fetchRandomProducts,
    fetchProduct,
    fetchProductRatings,
    fetchAttributes,
    fetchPrices,
    createOrder,
    placeKlarnaOrder,
    createRequest,
    createProductRating,
    onPayPalTransaction,
    settings,
  }}>
    <Helmet>
      <link rel="preconnect" href={process.env.GATSBY_API_ENDPOINT}/>
    </Helmet>
    {children}
  </EApiContext.Provider>
}

const query = graphql`
    query {
        directusProject {
            display_name
            translations {
                seo_title
                language
            }
        }
    }
`;