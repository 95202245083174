export const getVariantsPriceRange = (variants) => {
  const prices = [];
  variants.map(variant => { //eslint-disable-line
    prices.push(variant.price_brutto);
  })
  prices.sort((a, b) => a - b);
  const range = [prices[0], prices[prices.length - 1]];
  // If prices are the same, remove one
  if (range[0] === range[1]) {
    range.pop()
  }
  return range;
}

export const calculateAverageRating = (ratings) => {
  const sum = ratings.reduce((a, b) => a + b.rating, 0);
  return (sum / ratings.length) || 0;
};
