import React, { useContext, useEffect, useState } from 'react';
import Portal                                     from '@reach/portal';
import { Button, Switch }                         from '@chakra-ui/react';
import { FaAngleDown, FaAngleUp }                 from 'react-icons/fa';
import AnimateHeight                              from 'react-animate-height';
import { useFormState }                           from 'react-use-form-state';

import { GlobalContext } from '@interness/web-core/src/providers';
import ConditionalWrapper
                         from '@interness/web-core/src/components/structure/ConditionalWrapper/ConditionalWrapper';

import * as S                  from './styles';
import { Link }                from '../../elements';
import Spacer                  from '../../structure/Spacer/Spacer';
import { Header as RowHeader } from '../../structure/MoreExpander/styles';

const ConsentRow = ({ consent, state, setState }) => {
  const [formState, { checkbox }] = useFormState();
  const [height, setHeight] = useState(0);

  const toggle = () => {
    setHeight(height === 0 ? 'auto' : 0)
  }

  useEffect(() => {
    let localState = {
      ...state
    }
    localState[consent.function_id] = formState.values[consent.function_id];
    setState(localState)
  }, [formState.values])

  return (
    <div>
      <RowHeader height={height} onClick={toggle}>
        {height !== 0 ? <FaAngleUp/> : <FaAngleDown/>}
        <b style={{ marginRight: 'auto', paddingLeft: '10px' }}>{consent.display_name}</b>
        <Switch {...checkbox(consent.function_id)} colorScheme="brand"
                defaultChecked={consent.consentGiven} isDisabled={consent.locked}/>
      </RowHeader>
      <AnimateHeight height={height} duration={500} style={{ borderBottom: `1px solid #efefef` }}>
        <div style={{ padding: '0 0 10px 0' }}>
          <p>{consent.description}</p>
          <Link to={consent.link}>Zur Datenschutzerklärung</Link>
        </div>
      </AnimateHeight>
    </div>
  )
}

const CookieConsent = ({ showInPortal }) => {
  const [hasMounted, setHasMounted] = useState(false);
  const { userConsents, userConsentDispatcher, showCookieBanner, setShowCookieBanner } = useContext(GlobalContext);
  let initialFormState = userConsents.map(consent => ({ [consent.function_id]: consent.consentGiven }));
  initialFormState = initialFormState.reduce((result, item) => {
    const key = Object.keys(item)[0];
    result[key] = item[key];
    return result;
  }, {})

  const [formState, setFormState] = useState(initialFormState);

  const onAcceptAll = () => {
    let newState = formState
    Object.keys(formState).forEach(field => newState[field] = true);
    setFormState(newState);
    const newConsents = userConsents.map(consent => {
      return {
        ...consent,
        consentGiven: formState[consent.function_id]
      };
    });
    userConsentDispatcher({type: 'save', payload: newConsents});
    setShowCookieBanner(false)
  }

  const onAcceptSelection = () => {
    const newConsents = userConsents.map(consent => {
      return {
        ...consent,
        consentGiven: formState[consent.function_id]
      };
    });
    userConsentDispatcher({type: 'save', payload: newConsents});
    setShowCookieBanner(false);
  }

  useEffect(() => {
    if (!hasMounted) {
      setHasMounted(true);
    }
    if (window._paq) {
      const matomoConsent = userConsents.find(consent => consent.function_id === 'matomo').consentGiven;
      if (matomoConsent) {
        window._paq.push(['rememberConsentGiven']);
      } else {
        window._paq.push(['forgetCookieConsentGiven']);
      }
    }
  }, [userConsents, hasMounted]);

  if (!showCookieBanner) {
    return null;
  }

  if (!hasMounted) {
    return null;
  }

  return (
    <ConditionalWrapper condition={showInPortal} wrapper={children => <Portal>{children}</Portal>}>
      <S.Banner>
        <h3>Cookies <span role="img" aria-label="Cookie">🍪</span></h3>
        {userConsents.map(consent => <ConsentRow key={consent.function_id} consent={consent}
                                                  setState={setFormState} state={formState}/>)}
        <Spacer height={10}/>
        <div className="int-buttons">
          <Button variant="ghost" colorScheme="brand" onClick={onAcceptSelection}>Ausgewählte Cookies erlauben</Button>
          <Button colorScheme="brand" onClick={onAcceptAll}>Alle Cookies erlauben</Button>
        </div>
      </S.Banner>
    </ConditionalWrapper>
  )
};

export default CookieConsent;
