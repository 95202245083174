import React             from 'react';
import { useFooterData } from '@interness/web-core/src/hooks';
import Widgets           from '@interness/web-core/src/components/modules/Widgets';
import SocialMediaBar    from '@interness/web-core/src/components/modules/SocialMediaBar/SocialMediaBar';

import * as S from './styles';

const defaultWidgets = [
  {
    sort: 1,
    widget_name: 'address',
    position: 'footer',
    props: {},
    translations: [
      {
        language: 'de',
        header: 'Adresse'
      }
    ]
  },
  {
    sort: 2,
    widget_name: 'contact',
    position: 'footer',
    props: {},
    translations: [
      {
        language: 'de',
        header: 'Kontakt'
      }
    ]
  },
  {
    sort: 3,
    widget_name: 'openingHours',
    position: 'footer',
    props: {},
    translations: [
      {
        language: 'de',
        header: 'Öffnungszeiten'
      }
    ]
  },
  {
    sort: 4,
    widget_name: 'navigation',
    position: 'footer',
    props: {},
    translations: [
      {
        language: 'de',
        header: 'Rechtliches'
      }
    ]
  }
];

const Footer = () => {
  const data = useFooterData();
  const widgets = data.widgets.length > 0 ? data.widgets : defaultWidgets;
  return (
    <S.Footer>
      <S.Inner widgetsCount={widgets.length}>
        <Widgets widgets={widgets} lang={data.language}/>
      </S.Inner>
      <S.Social>
        <SocialMediaBar/>
      </S.Social>
    </S.Footer>
  )
};

export default Footer