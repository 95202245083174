import React from 'react';
import Root from './src/components/Root';

export const wrapRootElement = ({ element }) => {
  return <Root>{element}</Root>
}

// Reset Scroll everytime
export const shouldUpdateScroll = args => {
  window.scrollTo(0, 0);
  return false;
};
