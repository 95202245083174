import React        from 'react';
import { useTheme } from '@chakra-ui/react';

const InternessLogo = () => {
  const theme = useTheme();
  const isDark = theme.initialColorMode === 'dark';
  const logoFill = isDark ? '#FFFFFF' : '#3C3C3B';
  return (
    <div style={{ maxWidth: '280px', marginBottom: '20px' }}>
      {/*eslint-disable-next-line*/}
      <a href="https://screenandstore.de" target="_blank"
         style={{ display: 'flex', alignItems: 'baseline', textDecoration: 'none' }}>
        <svg version="1.1" viewBox="0 0 512 512" style={{ width: '100%' }}>
          <g>
            <path style={{ fill: logoFill }} d="M82.3,200.2v-10.4c5.3,0.6,9.8,0.9,12.8,0.9c8.8,0,12.9-2.8,12.9-8.6c0-4.3-2.5-7.4-8.8-11.5l-4.5-3
			c-8.9-5.8-12.9-11.3-12.9-18.7c0-10.8,8.4-17.5,22.3-17.5c3,0,6.7,0.3,10.4,0.8v9.5c-3.9-0.4-6.8-0.5-8.8-0.5
			c-8.1,0-12.4,2.7-12.4,7.6c0,3.6,2.6,6.4,8.9,10.6l4.5,3c9.3,6.1,13.3,11.7,13.3,19.6c0,12-9,19.1-24.5,19.1
			C92.3,201,87.4,200.8,82.3,200.2z"/>
            <path style={{ fill: logoFill }} d="M129,166.3c0-20.6,14.6-35,35-35c3.2,0,7,0.3,11,0.9v11c-4-0.6-7.4-0.9-9.7-0.9c-14.8,0-24.4,9.9-24.4,24
			c0,13.9,9.5,23.7,24.4,23.7c2.3,0,5.7-0.3,9.7-0.8v11c-4,0.6-7.7,0.9-11,0.9C143.6,201.1,129,186.8,129,166.3z"/>
            <path style={{ fill: logoFill }}
                  d="M209,132.1h7.6V143H210c-7.2,0-10.2,2.7-10.2,10.4v46.8H188v-46.7C188,138.8,194.9,132.1,209,132.1z"/>
            <path style={{ fill: logoFill }} d="M220.6,166.4c0-20.4,14.4-35.6,34.2-35.6c17.3,0,32.8,11.7,32.4,36.9h-55.5c0.6,13.4,9.9,23.3,24,23.3
			c9.9,0,18.1-4.9,23.1-13.2l8.3,6.1c-5.9,9.3-16.2,17.5-31.5,17.5C234.7,201.5,220.6,186.4,220.6,166.4z M275.5,158.8
			c-2.2-12.1-10.7-17.9-20.9-17.9c-11.5,0-19.9,7.6-22.3,17.9H275.5z"/>
            <path style={{ fill: logoFill }} d="M296.9,166.4c0-20.4,14.4-35.6,34.2-35.6c17.3,0,32.8,11.7,32.4,36.9H308c0.6,13.4,9.9,23.3,24,23.3
			c9.9,0,18.1-4.9,23.1-13.2l8.3,6.1c-5.9,9.3-16.3,17.5-31.5,17.5C311,201.5,296.9,186.4,296.9,166.4z M351.9,158.8
			c-2.2-12.1-10.7-17.9-20.9-17.9c-11.5,0-19.9,7.6-22.3,17.9H351.9z"/>
            <path style={{ fill: logoFill }} d="M403.4,130.6c16.8,0,26.8,10.7,26.8,28.8v40.9h-11.9v-40.5c0-11.6-5.3-17.9-15-17.9
			c-9.8,0-15.1,6.3-15.1,17.9v40.5h-11.9v-40.9C376.4,141.3,386.3,130.6,403.4,130.6z"/>
          </g>
          <g>
            <path style={{ fill: logoFill }} d="M124.4,380.6v-10.4c5.3,0.6,9.8,0.9,12.8,0.9c8.8,0,12.9-2.8,12.9-8.6c0-4.3-2.5-7.4-8.8-11.5l-4.5-3
			c-8.9-5.8-12.9-11.3-12.9-18.7c0-10.8,8.4-17.5,22.3-17.5c3,0,6.7,0.3,10.4,0.8v9.5c-3.9-0.4-6.8-0.5-8.8-0.5
			c-8.1,0-12.4,2.7-12.4,7.6c0,3.6,2.6,6.4,8.9,10.6l4.5,3c9.3,6.1,13.3,11.7,13.3,19.6c0,12-9,19.1-24.5,19.1
			C134.4,381.4,129.5,381.2,124.4,380.6z"/>
            <path style={{ fill: logoFill }}
                  d="M177.7,324.1h-8.3v-10.8h8.3v-18.8l11.9-4.4v23.2h8.8v10.8h-8.8v57.3h-11.9V324.1z"/>
            <path style={{ fill: logoFill }} d="M205.8,345.9c0-20.6,15.2-35.7,35.1-35.7c19.7,0,35,15.1,35,35.7c0,20.5-15.2,35.5-35,35.5
			C221,381.4,205.8,366.4,205.8,345.9z M240.9,370.2c13.2,0,23-10.2,23-24.2c0-14.3-9.8-24.5-23-24.5c-13.3,0-23.2,10.2-23.2,24.5
			C217.7,360,227.6,370.2,240.9,370.2z"/>
            <path
              style={{ fill: logoFill }}
              d="M309.9,313.3h7.6v10.8h-6.6c-7.2,0-10.2,2.7-10.2,10.4v46.8h-11.9v-46.7C288.9,320,295.8,313.3,309.9,313.3z"
            />
            <path style={{ fill: logoFill }} d="M321.5,346.3c0-20.4,14.4-35.6,34.2-35.6c17.3,0,32.8,11.7,32.4,36.9h-55.5c0.6,13.4,9.9,23.3,24,23.3
			c9.9,0,18.1-4.9,23.1-13.2l8.3,6.1c-5.9,9.3-16.3,17.5-31.5,17.5C335.6,381.4,321.5,366.3,321.5,346.3z M376.5,338.7
			c-2.2-12.1-10.7-17.9-20.9-17.9c-11.5,0-19.9,7.6-22.3,17.9H376.5z"/>
          </g>
          <g>
            <g>
              <path fill="#6E44FF" d="M252,272.9c0.2-0.3,0.4-0.6,0.7-1l-10.8-5.9c-3.9,4.9-10,12-13.5,12.9c-8,2.1-16.3-2.7-18.4-10.7
				c-1.3-4.7-0.1-9.7,2.9-13.3c-1.9-0.5-3.8-0.9-5.6-1.1c-1.8-0.3-3.5-0.5-5.4-0.6l-2-0.1c-2.6,5.7-3.2,12.1-1.6,18.2
				c1.9,7,6.3,12.8,12.6,16.5c6.3,3.6,13.6,4.6,20.6,2.8C237.5,288.9,244.3,283.2,252,272.9z"/>
              <path fill="#E54E60" d="M313.7,240.7c-1.9-7-6.3-12.8-12.6-16.5c-6.3-3.6-13.6-4.6-20.6-2.8c-6.1,1.6-12.8,7.4-20.6,17.7
				c-0.2,0.3-0.4,0.6-0.7,1l10.8,5.9c3.9-4.9,10-12,13.5-12.9c3.9-1,7.9-0.5,11.4,1.5c3.5,2,6,5.3,7,9.2c1.3,4.7,0.1,9.7-2.9,13.3
				c1.9,0.5,3.8,0.9,5.6,1.1c1.8,0.3,3.5,0.4,5.4,0.6l2,0.1C314.8,253.2,315.3,246.8,313.7,240.7z"/>
            </g>
            <path fill="#9A85FF" d="M305.6,267.9c-0.7-0.1-1.5-0.2-2.2-0.3c-4.9-0.7-10.1-2.6-15.6-4.5c-2-0.7-4.1-1.4-6.1-2.1
			c-3.3-1.1-9.6-3.4-15.2-6.2L252,247c-2.7-1.3-5.3-2.6-7.8-3.6c-11.8-5.1-20.5-6.8-26.6-5.2c-4.2,1.1-7.9,3.1-11.1,6
			c0.7,0.1,1.5,0.2,2.2,0.3c6.7,1,14.4,3.8,21.8,6.7c7.1,3,9.1,3.9,14.9,6.8l15.1,7.3c2.5,1.3,5,2.4,7.4,3.4
			c11.8,5.1,20.5,6.8,26.6,5.2C298.6,272.8,302.4,270.7,305.6,267.9z"/>
          </g>
        </svg>
      </a>
    </div>
  )
}

export default InternessLogo;
