import React, { useState, useEffect, useReducer } from 'react';
import { useStaticQuery, graphql }                from 'gatsby';
import { useDisclosure }                          from '@chakra-ui/react';

import { consentReducer, getConsentsFromLocal } from '../reducers/consentsReducer';

export const GlobalContext = React.createContext({});

export const GlobalProvider = ({ children }) => {
  let localConsents = false;
  if (typeof window !== 'undefined') {
    localConsents = !!window.localStorage.getItem('int-consents');
  }

  const [primaryMenuOpen, togglePrimaryMenu] = useState(false);
  const [showCookieBanner, setShowCookieBanner] = useState(!localConsents);
  const [sidebarContent, setSidebarContent] = useState(null);
  const { isOpen: isSidebarOpen, onOpen: onSidebarOpen, onClose: onSidebarClose } = useDisclosure();

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      setShowCookieBanner(false)
    }
    //eslint-disable-next-line
  }, [])

  const data = useStaticQuery(query);
  const matomoTracking = !!data.allSitePlugin.nodes.filter(plugin => plugin.name === 'gatsby-plugin-matomo');
  const remoteConsents = data.allDirectusConsents.nodes;
  const availableConsents = [{
    display_name: 'Funktionale Cookies',
    description: 'Nicht personen bezogene funktionale Cookies für Webseitenfunktionen',
    link: '/datenschutz#cookies',
    default_on: true,
    consentGiven: true,
    locked: true,
    function_id: 'functional'
  }];

  const remoteMatomoConsent = remoteConsents.find(el => el.function_id === 'matomo');

  if (!remoteMatomoConsent && matomoTracking) {
    availableConsents.push(
      {
        display_name: 'Matomo Analytics',
        description: 'Erfasst Statistiken über Besuche des Benutzers auf der Website, wie z. B. die Anzahl der Besuche, durchschnittliche Verweildauer auf der Website und welche Seiten gelesen wurden.',
        link: '/datenschutz#matomo-ehemals-piwik',
        default_on: true,
        consentGiven: true,
        locked: false,
        function_id: 'matomo'
      })
  }

  remoteConsents.forEach(consent => availableConsents.push({ ...consent, consentGiven: false, locked: false }));

  const [userConsents, userConsentDispatcher] = useReducer(consentReducer, availableConsents, getConsentsFromLocal)

  // TODO does not belong here
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const html = document.getElementsByTagName('html')[0];
      if (primaryMenuOpen) {
        html.style.overflow = 'hidden';
      } else {
        html.style.overflow = 'auto';
      }
    }
  }, [primaryMenuOpen]);

  return <GlobalContext.Provider
    value={{
      primaryMenuOpen, togglePrimaryMenu,
      showCookieBanner, setShowCookieBanner,
      userConsents, userConsentDispatcher,
      isSidebarOpen, onSidebarOpen, onSidebarClose,
      sidebarContent, setSidebarContent,
    }}>{children}</GlobalContext.Provider>
};

const query = graphql`
    query {
        allDirectusConsents(filter: {id: {ne: "dummy"}}) {
            nodes {
                display_name
                description
                link
                default_on
                function_id
            }
        }
        allSitePlugin {
            nodes {
                name
            }
        }
    }
`
