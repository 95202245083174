const LOCAL_STORAGE_KEY = 'int-consents';

const saveConsentsToLocal = (state) => {
  window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify([...state]));
}

export const getConsentsFromLocal = (initialConsents) => {
  if (typeof window !== 'undefined') {
    const localConsents = window.localStorage.getItem(LOCAL_STORAGE_KEY);
    if (localConsents) {
      return JSON.parse(localConsents);
    } else {
      return initialConsents;
    }
  } else {
    // SSR
    return [{
      display_name: 'Funktionale Cookies',
      description: 'Nicht personen bezogene funktionale Cookies für Webseitenfunktionen',
      link: '/datenschutz#cookies',
      default_on: true,
      consentGiven: true,
      locked: true,
      function_id: 'functional'
    }]
  }
}

const removeConsentsFromLocal = () => {
  window.localStorage.removeItem(LOCAL_STORAGE_KEY);
}

export const consentReducer = (state, action) => {
  let newState;

  switch (action.type) {
    case 'save':
      newState = [...action.payload];
      saveConsentsToLocal(newState);
      return newState;
    case 'reset':
      removeConsentsFromLocal();
      return null;
    default:
      console.log('Consent Reducer Action Type Not Found');
      break;
  }
}