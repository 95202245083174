import styled from '@emotion/styled';

export const Bar = styled.ul`
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  li {
    height: 36px;
    width: 36px;
    margin: 0 0 0 ${props => props.count === 1 ? '' : '5px'};
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      svg {
        fill: ${props => props.theme.brand_color};
        height: 16px;
        width: 16px;
      }
    }
  }
`;