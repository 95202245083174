import React, { useContext }       from 'react';
import { slide as Menu }           from 'react-burger-menu'
import { useStaticQuery, graphql } from 'gatsby';

import { GlobalContext } from '@interness/web-core/src/providers';
import NewMenuStructure  from '@interness/web-core/src/components/structure/MenuStructure/NewMenuStructure';

import * as S from './style';

const Sidebar = () => {
  const menuStyles = {
    bmMenuWrap: {
      position: 'fixed',
      height: '100%',
      width: '100%',
      maxWidth: '350px',
    },
  };

  const menuOptions = {
    right: true,
    pageWrapId: 'page-wrap',
    outerContainerId: 'outer-container',
    customBurgerIcon: false,
    customCrossIcon: false,
    noOverlay: false,
    styles: { ...menuStyles },
    disableOverlayClick: () => toggleMenu(),
    disableCloseOnEsc: true,
  };
  const { primaryMenuOpen, togglePrimaryMenu } = useContext(GlobalContext);
  const toggleMenu = () => togglePrimaryMenu(!primaryMenuOpen);
  const data = useStaticQuery(query);
  const menu = data.menu.config;
  return (
    <Menu isOpen={primaryMenuOpen} {...menuOptions}>
      <S.Sidebar>
        <NewMenuStructure menu={menu}/>
      </S.Sidebar>
    </Menu>
  )
};

const query = graphql`
  query {
    menu: directusMenusNew(position: {eq: "primary"}) {
      language
      position
      config {
        anchor
        display_name
        groups {
          display_name
          sub {
            anchor
            display_name
            path
          }
        }
        path
        route
        sub {
          display_name
          path
          path_prefix
        }
      }
    }
  }
`;

export default Sidebar;
