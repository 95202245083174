import React, { useContext, useEffect, useState } from 'react';
import ReactDOM                                   from 'react-dom';

import MenuStructure from '@interness/web-core/src/components/structure/MenuStructure/MenuStructure';

import { EApiContext } from '../../context/EApi';

const ShopNav = () => {
  const ecomLegal = [
    {
      displayName: 'AGB',
      path: '/agb'
    },
    {
      displayName: 'Widerruf',
      path: '/widerruf'
    },
    {
      displayName: 'Versand und Zahlung',
      path: '/versand-und-zahlung'
    }
  ]

  return <MenuStructure menu={ecomLegal}/>
}

const ShopNavPortal = () => {
  const [renderMenu, setRenderMenu] = useState(false);
  const { settings } = useContext(EApiContext);
  let navElement;

  if (typeof window !== 'undefined') {
    navElement = document.getElementById('int-shop-menu');
  }

  useEffect(() => {
    if (settings.data) {
      if (settings.data.enable_cart) {
        setRenderMenu(true);
      }
    }
  }, [settings, navElement])

  if (navElement && renderMenu) {
    return ReactDOM.createPortal(<ShopNav/>, navElement)
  } else {
    return null;
  }
}

export default ShopNavPortal