import React             from 'react';
import PropTypes         from 'prop-types';
import Img               from 'gatsby-image';
import SVG               from 'react-inlinesvg';
import styled            from '@emotion/styled';
import { readableColor } from 'polished';

const SVGContainer = styled.div`
  svg {
    fill: ${props => props.svgFill ? props.svgFill : readableColor(props.theme.base_color, '#000', '#fff')};
    width: ${props => props.width};
    height: ${props => props.height};
  }
`;

const Logo = ({ logo, title, svgFill, svgWidth, svgHeight, imageWidth }) => {
  if (!logo.childImageSharp && logo.extension === 'svg') {
    // SVG
    return <SVGContainer svgFill={svgFill} width={svgWidth} height={svgHeight}><SVG src={logo.publicURL} title={title}/></SVGContainer>
  } else {
    if (logo.childImageSharp.fixed) {
      // Fixed
      return <Img style={{ maxHeight: '80px' }} fixed={logo.childImageSharp.fixed} alt={title} title={title} loading={'eager'}/>
    } else {
      // Fluid
      return <Img style={{ maxHeight: '120px', maxWidth: imageWidth ? imageWidth : 'auto' }} imgStyle={{ objectFit: 'contain' }} fluid={logo.childImageSharp.fluid}
                  alt={title} title={title} loading={'eager'}/>
    }
  }
};

Logo.propTypes = {
  logo: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  svgFill: PropTypes.string,
  svgWidth: PropTypes.string,
  imageWidth: PropTypes.string,
};

export default Logo;
