import currency from 'currency.js';

const LOCAL_STORAGE_KEY = 'int-cart';

const saveCartToLocal = (state) => {
  if (state.totalCount === 0) {
    window.localStorage.removeItem(LOCAL_STORAGE_KEY);
  } else {
    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ timestamp: new Date().valueOf(), ...state }));
  }
}

export const getCartFromLocal = () => {
  if (typeof window !== 'undefined') {
    const localCart = window.localStorage.getItem(LOCAL_STORAGE_KEY);
    if (localCart) {
      return JSON.parse(localCart);
    } else {
      return { products: [], totalCount: 0, totalPriceBrutto: 0 };
    }
  }
}

const removeCartFromLocal = () => {
  window.localStorage.removeItem(LOCAL_STORAGE_KEY);
}

export const cartReducer = (state, action) => {
  let counter = 0;
  let priceBrutto = 0;
  let localCart = [...state.products];
  let newState;
  let cartIndex;

  if (action.type === 'add') {
    cartIndex = localCart.findIndex(item => {
      const sameProduct = item.product.id === action.payload.product.id;
      const sameVariant = item.variant === action.payload.variant;
      const samePersonalization = item.personalization ? action.payload.personalization && JSON.stringify(item.personalization.values) === JSON.stringify(action.payload.personalization) : !item.personalization && !action.payload.personalization;
      return sameProduct && sameVariant && samePersonalization;
    });

    if (cartIndex !== -1) {
      action.type = 'changeCount';
      action.payload.cartId = localCart[cartIndex].cartId;
      action.payload.newCount = localCart[cartIndex].count + action.payload.count;
    }
  }

  switch (action.type) {
    case 'add':
      localCart.push({
        cartId: localCart.length + 1,
        ...action.payload
      });
      break;
    case 'changeCount':
      const itemIndex = localCart.findIndex(item => item.cartId === action.payload.cartId);
      localCart[itemIndex].count = action.payload.newCount;
      break;
    case 'remove':
      const index = localCart.findIndex(item => item.cartId === action.payload.id);
      localCart.splice(index, 1);
      break;
    case 'reset':
      removeCartFromLocal()
      return state = { products: [], totalCount: 0, totalPriceBrutto: 0 };
    default:
      console.log('Cart Reducer Action Type not found');
      break;
  }

  localCart.forEach(cartItem => {
    counter = counter + cartItem.count;
    priceBrutto = currency(priceBrutto).add(currency(cartItem.priceBrutto).multiply(cartItem.count)).value;
  });

  state.totalPriceBrutto = priceBrutto;
  state.totalCount = counter;
  state.products = localCart;
  newState = { ...state };
  saveCartToLocal(newState);
  return newState;
};
