import React, { useContext, useEffect, useState } from 'react';
import HamburgerMenu                              from 'react-hamburger-menu';
import Breakpoint                                 from 'react-socks';

import { useHeaderData } from '@interness/web-core/src/hooks';
import Logo              from '@interness/web-core/src/components/media/Logo/Logo';
import NewMenuStructure  from '@interness/web-core/src/components/structure/MenuStructure/NewMenuStructure';
import { GlobalContext } from '@interness/web-core/src/providers';
import Link              from '@interness/web-core/src/components/elements/Link/Link';

import * as S from './styles';

const Header = () => {
    const [mounted, setMounted] = useState(false);
    const data = useHeaderData()
    const { primaryMenuOpen, togglePrimaryMenu } = useContext(GlobalContext);
    const toggleMenu = () => togglePrimaryMenu(!primaryMenuOpen);

    useEffect(() => {
      setMounted(true)
    }, [])

    const menuFirstHalf = data.menuNew.slice(0, data.menuNew.length / 2);
    const menuSecondHalf = data.menuNew.slice(data.menuNew.length / 2);

    return (
      <S.Header>
        {mounted &&
        <S.Nav>
          <Breakpoint large up>
            <NewMenuStructure menu={menuFirstHalf}/>
          </Breakpoint>
          <Breakpoint medium down>
            <HamburgerMenu isOpen={primaryMenuOpen} menuClicked={toggleMenu} strokeWidth={3} borderRadius={5}/>
          </Breakpoint>
        </S.Nav>
        }
        <S.Logo>
          <Link to="/"><Logo logo={data.logo.localFile} title={data.seo_title}/></Link>
        </S.Logo>
        {mounted && <S.Nav second>
          <Breakpoint large up>
            <NewMenuStructure menu={menuSecondHalf}/>
          </Breakpoint>
        </S.Nav>}
        <S.CartSection id="int-cart"/>
        {!mounted &&
        <HamburgerMenu isOpen={primaryMenuOpen} menuClicked={toggleMenu} strokeWidth={3} borderRadius={5}/>}
      </S.Header>
    )
  }
;

export default Header;