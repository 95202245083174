import { useContext }              from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { LanguageContext } from '../providers';

export const useSocialMediaBarData = () => {
  const { language } = useContext(LanguageContext);
  const { directusMenus, directusMenusNew } = useStaticQuery(
    graphql`
        query {
            directusMenus(position: {eq: "social"}) {
                ...Menus
            }
            directusMenusNew(position: {eq: "social"}) {
                language
                position
                config {
                    display_name
                    external_path
                    media
                }
            }
        }
    `
  );
  return {
    language,
    networks: directusMenus,
    networksNew: directusMenusNew
  }
};