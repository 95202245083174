import { useStaticQuery, graphql } from 'gatsby';
import { find }                    from 'lodash';

export const useContact = () => {
  const data = useStaticQuery(query).directusCompany;
  const address = data.addresses[0];
  const email = find(data.emails, email => email.primary);
  const phoneNumber = find(data.numbers, number => (number.type === 'phone' || number.type === 'mobile') && number.primary);
  const faxNumber = find(data.numbers, number => number.type === 'fax' && number.primary);
  return {
    company_name: data.company_name,
    display_name: data.display_name,
    executives: data.executives,
    address,
    email,
    phoneNumber,
    faxNumber,
    legal_form: data.legal_form,
  }
};

const query = graphql`
  query {
    directusCompany {
      addresses {
        city
        country
        number
        street
        zip_code
        address_type
      }
      company_name
      display_name
      legal_form
      emails {
        email
        primary
      }
      executives {
        name
      }
      numbers {
        number
        primary
        type
      }
    }
  }
`;
