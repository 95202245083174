import React, { useState }            from 'react';
import PropTypes                      from 'prop-types';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { IoMdArrowDropdown }          from 'react-icons/io';
import Portal                         from '@reach/portal';
import AnimateHeight                  from 'react-animate-height';

import { NGroups } from './styles';

import Link     from '../../elements/Link/Link';
import Expander from '../Expander/Expander';

const SubNav = ({ parentPath, sub, onLinkClick }) => {
  return (
    <ul className="subnav">
      {sub.map(subpage => {
        let subroute;
        if (subpage.external_path) {
          subroute = subpage.external_path
        } else {
          if (subpage.path_prefix) {
            subroute = `/${parentPath}/${subpage.path}`;
          } else {
            subroute = `/${subpage.path}`;
          }
        }
        return (
          <li key={subpage.display_name}>
            <Link activeClassName={'current'} className={subpage.type ? subpage.type : null}
                  to={subroute} anchor={subpage.anchor}
                  onClick={onLinkClick}>{subpage.display_name}</Link>
          </li>
        );
      })}
    </ul>
  )
};

const Groups = ({ parentPath, groups, isOpen, onLinkClick, renderGroupsInPortal }) => {
  if (renderGroupsInPortal) {
    return (
      <Portal>
        <AnimateHeight duration={200} height={isOpen ? 'auto' : 0}
                       style={{ position: 'absolute', top: '170px', left: 0, right: 0, zIndex: 1 }}>
          <NGroups className="int-groups" visible={isOpen}>
            {groups.map(group => (
              <div key={group.display_name} className="int-group">
                <p>{group.display_name}</p>
                <SubNav parentPath={parentPath} sub={group.sub} onLinkClick={onLinkClick}/>
              </div>
            ))}
          </NGroups>
        </AnimateHeight>
      </Portal>
    )
  } else {
    return (
      <AnimateHeight duration={200} height={isOpen ? 'auto' : 0}>
        <div className="int-groups-inline">
          {groups.map(group => (
            <div key={group.display_name} className="int-group-inline">
              {renderGroupsInPortal && <p>{group.display_name}</p>}
              <Expander
                title={group.display_name}
                close={!isOpen}
              >
                <SubNav sub={group.sub} onLinkClick={onLinkClick}/>
              </Expander>
            </div>
          ))}
        </div>
      </AnimateHeight>
    )
  }
};

const NewMenuStructure = ({ menu, renderGroupsInPortal }) => {
  const [groupOpen, setGroupOpen] = useState({ open: {} });

  const toggleGroup = (i) => {
    setGroupOpen(prevState => {
      const open = { ...prevState.open };
      Object.keys(open).forEach(v => {
        v = parseInt(v);
        if (v !== i) {
          delete open[v];
        }
      });
      open[i] = !open[i];
      return { open };
    })
  };

  const closeGroups = () => {
    const open = { ...groupOpen };
    Object.keys(open).forEach(v => {
      v = parseInt(v);
      return open[v] = false;
    });
    setGroupOpen({ open: open })
  };

  const Chevron = ({ direction }) => {
    if (direction === 'up') {
      return <FaChevronUp/>
    } else {
      return <FaChevronDown/>
    }
  };

  return (
    <>
      <ul>
        {menu.map((page, i) => {
          const hasGroup = page.groups && page.groups.length > 0;
          let path;
          if (page.path === '') {
            path = '/'
          }
          if (page.external_path) {
            path = page.external_path
          } else {
            if (page.path !== '/') {
              path = '/' + page.path
            }
          }
          return (
            <li key={page.display_name}>
              {hasGroup
                ?
                <button style={{ cursor: 'pointer' }} className={groupOpen.open[i] ? 'active' : ''}
                        onClick={() => toggleGroup(i)}>{page.display_name} <Chevron
                  direction={groupOpen.open[i] ? 'up' : 'down'}/>
                </button>
                : <Link activeClassName={'current'} anchor={page.anchor} className={page.type ? page.type : null}
                        to={path}
                        partiallyActive={path !== '/'}
                        onClick={closeGroups}>{page.display_name} {page.sub &&
                <IoMdArrowDropdown/>}</Link>
              }
              {page.sub &&
              <SubNav parentPath={page.path} sub={page.sub} onLinkClick={closeGroups}/>
              }
              {page.groups &&
              <Groups parentPath={page.path} groups={page.groups} isOpen={groupOpen.open[i]}
                      renderGroupsInPortal={renderGroupsInPortal}
                      onLinkClick={closeGroups}/>
              }
            </li>
          );
        })}
      </ul>
    </>
  )
}

NewMenuStructure.propTypes = {
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      display_name: PropTypes.string.isRequired,
      path: PropTypes.string,
      sub: PropTypes.arrayOf(
        PropTypes.shape({
          display_name: PropTypes.string.isRequired,
          path: PropTypes.string.isRequired,
        })
      )
    })
  ),
  renderGroupsInPortal: PropTypes.bool
};

NewMenuStructure.defaultProps = {
  renderGroupsInPortal: true,
};

export default NewMenuStructure;
