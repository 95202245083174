import { useContext }              from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { LanguageContext } from '../providers';

export const useFooterData = () => {
  const { language } = useContext(LanguageContext);
  const { allDirectusWidgets } = useStaticQuery(
    graphql`
      query {
        allDirectusWidgets(filter: {position: {eq: "footer"}}) {
          nodes {
            ...Widgets
          }
        }
      }
    `
  );
  return {
    language,
    widgets: allDirectusWidgets.nodes
  }
};
