import React             from 'react';
import styled            from '@emotion/styled';
import PropTypes         from 'prop-types';
import { readableColor } from 'polished';

export const Button = (props) => {
  return <StyledButton {...props} />
}

// Primary | Flat
const StyledButton = styled.button`
  &[disabled] {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
  ${props => {
  if (props.primary) {
    if (props.accentColor) {
      return (`
          background-color: ${props.accentColor};
          border-color: ${props.accentColor} !important;
          color: ${readableColor(props.accentColor)};
          &:hover {
            background-color: unset;
            color: ${props.accentColor};
          }
        `);
    } else if (props.theme.config.components.button.accentColor !== '') {
      return (`
          background-color: ${props.theme.config.components.button.accentColor};
          border-color: ${props.theme.config.components.button.accentColor} !important;
          color: ${readableColor(props.theme.config.components.button.accentColor)};
          &:hover {
            background-color: unset;
            color: ${props.theme.config.components.button.accentColor};
          }
        `);
    } else {
      return (`
          background-color: ${props.theme.brand_color};
          border-color: ${props.theme.brand_color} !important;
          color: ${readableColor(props.theme.brand_color)};
          &:hover {
            background-color: unset;
            color: ${props.theme.brand_color};
          }
        `);
    }
  } else {
    if (props.accentColor) {
      return (`
          background-color: ${props.theme.base_color};
          border-color: ${props.accentColor} !important;
          color: ${props.accentColor};
          &:hover {
            background-color: ${props.accentColor}
            color: ${readableColor(props.accentColor)};
          }
        `);
    } else if (props.theme.config.components.button.accentColor !== '') {
      return (`
          background-color: ${props.theme.base_color};
          border-color: ${props.theme.config.components.button.accentColor} !important;
          color: ${props.theme.config.components.button.accentColor};
          &:hover {
            background-color: ${props.theme.config.components.button.accentColor};
            color: ${readableColor(props.theme.config.components.button.accentColor)};
          }
        `);
    } else {
      return (`
          background-color: ${props.theme.base_color};
          border-color: ${props.theme.brand_color} !important;
          color: ${props.theme.brand_color};
          &:hover {
            background-color: ${props.theme.brand_color};
            color: ${readableColor(props.theme.brand_color)};
          }
        `);
    }
  }
}};
  color: ${props => {
  if (props.color) {
    return props.color;
  } else if (props.theme.config.components.button.color !== '') {
    return props.theme.config.components.button.color;
  }
}};
  border-radius: ${props => {
  if (props.borderRadius) {
    return props.borderRadius;
  } else if (props.theme.config.components.button.borderRadius !== '') {
    return props.theme.config.components.button.borderRadius;
  }
}};
  border: ${props => props.borderThickness ? props.borderThickness : props.theme.config.components.button.borderThickness} solid;
  ${props => props.flat ? `border: 0px;` : null};
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  cursor: pointer;
  //TODO Outline none is bad accessibility
  outline: none;
  transition: background-color 0.2s ease;
  padding: 12px 20px;
  text-align: center;
  display: inline-block;
  width: ${props => props.fullWidth ? '100%' : 'auto'};
  > div {
    line-height: 2;
  }
`;

Button.propTypes = {
  fullWidth: PropTypes.bool,
  accentColor: PropTypes.string,
  color: PropTypes.string,
  borderRadius: PropTypes.string,
  borderThickness: PropTypes.string,
  textTransform: PropTypes.string,
  primary: PropTypes.bool,
  flat: PropTypes.bool
};

export default Button;
