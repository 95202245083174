import React                       from 'react';
import { useStaticQuery, graphql } from 'gatsby';

export const ContactContext = React.createContext({});

export const ContactProvider = ({ children }) => {
  const company = useStaticQuery(query).directusCompany;

  return <ContactContext.Provider
    value={{ ...company }}>{children}</ContactContext.Provider>
};

const query = graphql`
  query {
    directusCompany {
      ...Company
    }
  }
`;
