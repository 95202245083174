import React, { useState, useEffect } from 'react';

import Spacer from '../../structure/Spacer/Spacer';

const CONSENT_IDENTIFIER = 'contentConsent:';

const ContentConsentManager = () => {

  const [consents, setConsents] = useState([]);

  const loadAndSetConsentsFromLocalStorage = async () => {
    if (typeof window !== 'undefined') {
      const items = { ...window.localStorage };
      const consents = [];
      await Object.keys(items).forEach(item => {
        if (item.startsWith(CONSENT_IDENTIFIER)) {
          consents.push(item);
        }
      })
      await setConsents(consents);
    }
  }

  useEffect(() => {
    loadAndSetConsentsFromLocalStorage()
  }, [])

  const handleCheckboxClicked = async (consent) => {
    // const lconsents = consents;
    // delete lconsents[consents.findIndex(c => c === consent)];
    // await setConsents(lconsents);
    window.localStorage.removeItem(consent);
  }

  if (consents.length === 0) {
    return null;
  }

  return (
    <div>
      <Spacer/>
      <h1>Zustimmungen verwalten</h1>
      <h2>Externe Inhalte</h2>
      <ul>
        {consents.map(consent =>
          <li key={consent}>
            <input type='checkbox' id={consent} onClick={() => handleCheckboxClicked(consent)} defaultChecked/>
            {/*eslint-disable-next-line*/}
            <label htmlFor={consent}>{consent.slice(CONSENT_IDENTIFIER.length, consent.length)}</label>
          </li>
        )}
      </ul>
      <Spacer/>
      <h2>Matomo Tracking</h2>
      <iframe style={{ border: 'none', width: '100%' }} title='Matomo Consent'
              src="https://matomo.interness.de/index.php?module=CoreAdminHome&action=optOut&language=de&backgroundColor=&fontColor=&fontSize=&fontFamily=Open%20Sans"/>
    </div>
  )
};

export default ContentConsentManager;