import styled from '@emotion/styled';

export const NGroups = styled.div`
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: space-around;
  margin: auto;
  padding: 30px 60px;
  background-color: ${props => props.theme.base_color};
  border-bottom: 5px solid ${props => props.theme.brand_color};
  .int-group {
    width: 25%;
    p {
      font-weight: bold;
    }
  }
  .subnav {
    margin: 0;
    li {
      list-style-type: none;
      margin: 0;
      padding: 0.2rem 0;
      a {
        color: unset;
        &:hover {
          color: ${props => props.theme.brand_color};
        }
        &.current {
          color: ${props => props.theme.brand_color};
          font-weight: bold;
          border-bottom: 3px solid ${props => props.theme.brand_color};
        }
      }
    }
  }
`;