import React from 'react';

import { useContact }                         from '../../hooks/useContact';
import PhoneNumber                            from '../../components/text/PhoneNumber/PhoneNumber';
import { addressTypeRenderer, legalRenderer } from '../../components/templates/LegalSidebar/LegalSidebar';
import Spacer                                 from '../../components/structure/Spacer/Spacer';

// TODO translate...
const ContactInfo = () => {
  const { company_name, phoneNumber, email, address, executives, legal_form } = useContact();
  return (
    <>
      <p style={{marginBottom: 0}}>{company_name}</p>
      {address.address_type && <i>{addressTypeRenderer(address.address_type)}</i>}
      <Spacer height={10} />
      <b>{legalRenderer(legal_form)}</b>
      {executives.map(({ name }) => <p style={{marginBottom: 0}} key={name}>{name}</p>)}
      <Spacer height={20} />
      <p>{address.street} {address.number} <br/> {address.zip_code} {address.city}</p>
      <p>Telefon: <PhoneNumber number={phoneNumber.number} callable={false}/></p>
      <p>E-Mail: {email.email}</p>
    </>
  )
};

export default ContactInfo;
