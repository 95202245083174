import styled from '@emotion/styled';

export const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 15px 0;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
  color: ${props => props.height !== 0 && props.theme.brand_color};
  svg {
    vertical-align: bottom !important;
    margin-right: 10px;
  }
`;
