import styled            from '@emotion/styled';
import { readableColor } from 'polished';

export const Box = styled.div`
  background-color: ${props => props.theme.base_color};
  z-index: 1000;
  position: fixed;
  bottom: 20px;
  left: ${props => props.collapsed ? '0' : '20px'};
  box-shadow: 0 10px 30px 0 rgba(0,0,0,0.21);
  border-radius: 4px;
  padding: ${props => props.collapsed ? '8px' : '20px'};
  transition: all 0.3s;
  width: ${props => props.collapsed ? '50px' : 'auto'};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 720px) {
    width: ${props => props.collapsed ? '50px' : '100%'};
    right: 0;
    left: 0;
    bottom: ${props => props.collapsed ? '20px' : '0'};
    text-align: center;
  }
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  button {
    border: none;
    background: unset;
    cursor: pointer;
  }
  h4 {
    margin: 0 0 0 10px;
    display: ${props => props.collapsed ? 'none' : 'block'};
  }
  svg {
    height: 36px;
    width: 36px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: ${props => props.collapsed ? 'column' : 'row'};
  align-content: center;
  align-items: center;
  justify-content: ${props => props.collapsed ? 'center' : 'flex-start'};
  b {
    margin-right: ${props => props.collapsed ? '0' : '10px'};
  }
  svg {
    fill: ${props => props.theme.brand_color};
    vertical-align: text-top;
  }
  @media (max-width: 720px) {
    justify-content: center;
  }
`;

export const Count = styled.div`
  p {
    font-size: 0.6rem;
    margin-bottom: ${props => props.collapsed ? '0' : '15px'};
  }
`;

export const Actions = styled.div`
  text-align: center;
  transform: scale(${props => props.collapsed ? '0' : '1'});
  display: ${props => props.collapsed ? 'none' : 'block'};
  a {
    display: block;
    &.int-rate {
      margin-bottom: 15px;
      padding: 5px 10px;
      border-radius: 30px;
      background-color: ${props => props.theme.brand_color};
      color: ${props => readableColor(props.theme.brand_color)};
      transition: background-color 0.2s;
      text-decoration: none;
      &:hover {
        text-decoration: none;
        background-color: ${props => props.theme.base_color};
        color: unset;
      }
    }
    &.int-read-reviews {
      font-size: 0.8rem;
    }
  }
  button {
    background: unset;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 0.6rem;
    color: unset;
    opacity: 0.9;
  }
`;