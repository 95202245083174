import React, { useEffect, useState } from 'react';
import { FcGoogle }                   from 'react-icons/fc';
import { FaStar, FaRegStar }          from 'react-icons/fa';
import Rating                         from 'react-rating';
import { useCurrentWidth }            from 'react-socks';

import * as S    from './styles';
import useScript from '../../hooks/useScript';

const KEY = process.env.GATSBY_GOOGLE_API_KEY || 'AIzaSyBJpTGEoUzZ736YM0FPxehRA52I4h3cwyg';

const GoogleRatingBox = ({ placeId }) => {
  const [loaded] = useScript(`https://maps.googleapis.com/maps/api/js?key=${KEY}&libraries=places`);
  const width = useCurrentWidth();
  const [collapsed, setCollapsed] = useState(true);
  const [mounted, setMounted] = useState(false);
  const [googleData, setGoogleData] = useState({ status: 'LOADING', place: {} });

  const onToggleCollapse = () => {
    setCollapsed(!collapsed);
  }

  const googleServiceCallback = (place, status) => {
    setGoogleData({
      status,
      place
    })
  }

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
    //TODO fix this...
    if (!googleData.status !== 'LOADING') {
      if (process.env.NODE_ENV === 'development') {
        setGoogleData({
          status: 'OK',
          place: {
            rating: 4.5,
            user_ratings_total: 30
          }
        })
      } else if (typeof window !== 'undefined') {
        if (loaded) {
          const req = {
            placeId: placeId,
            fields: ['rating', 'user_ratings_total'],
          }
          let service;
          try {
            service = new window.google.maps.places.PlacesService(document.getElementById('int-google-rating-box'))
            service.getDetails(req, googleServiceCallback)
          } catch (e) {
            console.log('Error fetching place details', e);
          }
        }
      }
    }

    if (width < 1200 || process.env.NODE_ENV === 'development') {
      setCollapsed(true)
    } else {
      setCollapsed(false)
    }

  }, [loaded, placeId, googleData.status, width, mounted]);

  if (!mounted) {
    return null;
  }

  if (googleData.status === 'OK') {
    return (
      <S.Box collapsed={collapsed}>
        <S.Head collapsed={collapsed}>
          <button onClick={onToggleCollapse} aria-label='Bewertungsbox ein- oder ausblenden'><FcGoogle/></button>
          <h4>Google Bewertungen</h4>
        </S.Head>
        <S.Content collapsed={collapsed}>
          <b>{googleData.place.rating}</b>
          {collapsed
            ? <FaStar/>
            : <Rating
              readonly
              fractions={3}
              initialRating={googleData.place.rating}
              emptySymbol={<FaRegStar/>}
              fullSymbol={<FaStar/>}
            />}
        </S.Content>
        <S.Count collapsed={collapsed}>
          {!collapsed && <p>Basierend auf {googleData.place.user_ratings_total} Bewertungen.</p>}
          {collapsed && <p>{googleData.place.user_ratings_total}</p>}
        </S.Count>
        <S.Actions collapsed={collapsed}>
          <a className='int-rate' href={`https://search.google.com/local/writereview?placeid=${placeId}`}
             target='_blank'
             rel='noopener noreferrer'>Jetzt Bewertung
            abgeben</a>
          <a className='int-read-reviews' href={`https://search.google.com/local/reviews?placeid=${placeId}`}
             target='_blank'
             rel='noopener noreferrer'>Alle Bewertungen ansehen</a>
          <button onClick={onToggleCollapse}>Nicht jetzt</button>
        </S.Actions>
        <div id='int-google-rating-box' style={{ display: 'none' }}/>
      </S.Box>
    )
  } else if (googleData.status !== 'OK') {
    return <div id='int-google-rating-box' style={{ display: 'none' }}/>;
  }
};

export default GoogleRatingBox;