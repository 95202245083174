import React                                                        from 'react';
import { StaticQuery, graphql }                                     from 'gatsby';
import { FaExternalLinkAlt, FaFacebook, FaGooglePlus, FaInstagram } from 'react-icons/fa';
import styled                                                       from '@emotion/styled';

import { findT } from '../../_helpers';

const Ul = styled.ul`
  svg {
    vertical-align: middle;
    margin-right: 5px;
  }
`;

function mediaRenderer(network) {
  switch (network) {
    case 'facebook':
      return <FaFacebook/>;
    case 'googleplus':
      return <FaGooglePlus/>;
    case 'instagram':
      return <FaInstagram/>;
    default:
      return <FaExternalLinkAlt/>;
  }
}

const SocialWidget = props => {
  const translations = findT(props.widget.translations, props.lang);
  return <StaticQuery query={query} render={data => {
    const networks = data.directusMenusNew.config;

    return (
      <>
        <h4>{translations.header
          ? translations.header
          : 'Soziale Netze'}
        </h4>
        <nav>
          <Ul>
            {networks.map((network) => {
              if (network.display_name !== '') {
                return (
                  <li key={network.media}>
                    <a href={network.external_path} title={network.display_name} target='_blank'
                       rel='noopener noreferrer'
                       data-tip={network.display_name}>
                      {mediaRenderer(network.media)}
                      {network.display_name}
                    </a>
                  </li>
                );
              } else {
                return null;
              }
            })}
          </Ul>
        </nav>
      </>
    )
  }}/>
};

const query = graphql`
  query {
    directusMenusNew(position: {eq: "social"}) {
      config {
        display_name
        external_path
        media
      }
    }
  }
`;

export default SocialWidget;
