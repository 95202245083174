import styled    from '@emotion/styled';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  width: 90%;
  max-width: ${props => props.width ? props.width : props.theme.config.components.wrapper.width}px;
  margin: auto;
`;

Wrapper.propTypes = {
  width: PropTypes.number,
};

export default Wrapper;
